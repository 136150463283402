import { LoaderPinwheel, X } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import useOrderStore from '@/context/useOrderStore'
import { countries } from '../assets/countries';

const ShippingForm = ({ onSubmit, onCancel, shippingModalOpen, updateOrderOnServer }) => {
  const { orderId } = useParams()
  const navigate = useNavigate()
  const { toPay, order, updateShipping, stripeCheckoutActive, setStripeCheckoutActive } = useOrderStore()
  const [form, setForm] = useState({
    shipping_name: '',
    shipping_address_1: '',
    shipping_address_2: '',
    shipping_city: '',
    shipping_state: '',
    shipping_postal_code: '',
    shipping_country_name: '',
    shipping_country_code: '',
    shipping_phone_number: '',
    shipping_delivery_notes: '',
    shipping_email: ''
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    let tempErrors = {};
    if (!form.shipping_name || form.shipping_name.length < 2) tempErrors.shipping_name = 'Name must be at least 2 characters';
    if (!form.shipping_address_1 || form.shipping_address_1.length < 2) tempErrors.shipping_address_1 = 'Address 1 must be at least 2 characters';
    if (form.shipping_address_2 && form.shipping_address_2.length < 2) tempErrors.shipping_address_2 = 'Address 2 must be at least 2 characters';
    if (!form.shipping_city || form.shipping_city.length < 2) tempErrors.shipping_city = 'City must be at least 2 characters';
    if (!form.shipping_state || form.shipping_state.length < 2) tempErrors.shipping_state = 'State must be at least 2 characters';
    if (!form.shipping_postal_code || form.shipping_postal_code.length < 2) tempErrors.shipping_postal_code = 'Postal code must be at least 2 characters';
    if (!form.shipping_country_name) tempErrors.shipping_country_name = 'Country name is required';
    if (!form.shipping_phone_number || !/^\+?\d{10,15}$/.test(form.shipping_phone_number)) tempErrors.shipping_phone_number = 'Valid phone number is required';
    if (!form.shipping_email || !/\S+@\S+\.\S+/.test(form.shipping_email)) tempErrors.shipping_email = 'Valid email is required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const finishOrder = async() => {
    const updatedOrder = updateShipping(form)
    await updateOrderOnServer(updatedOrder)
    setStripeCheckoutActive(true)
    try {
      const stripeCheckout = await fetch('https://swbytn6553.execute-api.eu-west-2.amazonaws.com/prod/stripe/paymentSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({order_id: orderId}),
      })
      if (!stripeCheckout.ok) {
        setStripeCheckoutActive(false)
        console.log('Network response not okay while fetching Stripe checkout session')
        return false
      }

      const data = await stripeCheckout.json()
      const checkout_url = data.checkout_url
      const payment_required = data.paymentRequired
      if (payment_required) {
        window.location.href = checkout_url
      } else {
        navigate('/success/' + orderId)
      }
    } catch (error) {
      console.log('Error updating order:', error);
    } finally {
      setStripeCheckoutActive(false)
    }
  }


  useEffect(() => {
    setIsFormValid(validateForm());
  }, [form]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({
      ...touched,
      [name]: true
    });
  };

  const handleCountryChange = (e) => {
    const { value } = e.target;
    const selectedCountry = countries.find(country => country.name === value);
    setForm({
      ...form,
      shipping_country_name: value,
      shipping_country_code: selectedCountry ? selectedCountry.code : ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // onSubmit(form);
      
      finishOrder()
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  const getInputClass = (field) => {
    return `w-full px-4 md:px-4 3xl:px-8 py-2 md:py-2 3xl:py-4 text-sm md:text-sm 3xl:text-lg border-2 transition-all ease-kibu duration-100 
    ${
      (errors[field] && touched[field]) ? 'border-grey/50 bg-red/10' : 'border-grey/30'
    } rounded-full text-lg focus:border-orange focus:outline-none`;
  };

  return (
    <div className="max-h-screen pt-20 pb-20 overflow-y-auto">
      <div className='flex flex-wrap w-full gap-2'>
        <form className={`w-[100%] max-w-[450px] 3xl:max-w-[600px] relative mx-auto p-4 bg-white shadow-md rounded-xl transition-all ease-kibu duration-300
          ${shippingModalOpen ? 'scale-100 opacity-100 delay-300' : 'scale-90 opacity-0 delay-0'}`} 
          onSubmit={handleSubmit}>
          <div className="font-bold text-sm md:text-base 3xl:text-3xl text-center w-full mt-2 md:mt-4 mb-4 md:mb-8">
            <div>Please Enter Your Shipping Information</div>
            {toPay && <div className='text-sm font-normal'>You'll be forwarded to Stripe for checkout after</div>}
          </div>
          <div className='flex flex-wrap w-full gap-2'>
            <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_name"
                value={form.shipping_name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Name"
                className={getInputClass('shipping_name')}
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_name ? 'opacity-30' : 'opacity-0'}`}>Name</span>
            </div>
            <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_address_1"
                value={form.shipping_address_1}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Address 1"
                className={getInputClass('shipping_address_1')}
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_address_1 ? 'opacity-30' : 'opacity-0'}`}>Address 1</span>
            </div>
            <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_address_2"
                value={form.shipping_address_2}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Address 2"
                className={getInputClass('shipping_address_2')}
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_address_2 ? 'opacity-30' : 'opacity-0'}`}>Address 2</span>
            </div>
            <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_city"
                value={form.shipping_city}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="City"
                className={getInputClass('shipping_city')}
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_city ? 'opacity-30' : 'opacity-0'}`}>City</span>
            </div>
            <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_state"
                value={form.shipping_state}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="State"
                className={getInputClass('shipping_state')}
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_state ? 'opacity-30' : 'opacity-0'}`}>State</span>
            </div>
            <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_postal_code"
                value={form.shipping_postal_code}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Postal Code"
                className={getInputClass('shipping_postal_code')}
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_postal_code ? 'opacity-30' : 'opacity-0'}`}>Postal Code</span>
            </div>
            <div className='min-w-[50%] flex-1 relative'>
              <select
                name="shipping_country_name"
                value={form.shipping_country_name}
                onChange={handleCountryChange}
                onBlur={handleBlur}
                className={getInputClass('shipping_country_name')}
              >
                <option value="" disabled>Country Name</option>
                {countries.map(country => (
                  <option key={country.name} value={country.name}>{country.name}</option>
                ))}
              </select>
              {/* <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_country_name ? 'opacity-30' : 'opacity-0'}`}>Country Name</span> */}
            </div>
            {/* <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_country_code"
                value={form.shipping_country_code}
                placeholder="Country Code"
                className={`${getInputClass('shipping_country_code')} bg-gray-200`}
                disabled
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_country_code ? 'opacity-30' : 'opacity-0'}`}>Country Code</span>
            </div> */}
            <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_phone_number"
                value={form.shipping_phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Phone Number"
                className={getInputClass('shipping_phone_number')}
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_phone_number ? 'opacity-30' : 'opacity-0'}`}>Phone Number</span>
            </div>
            <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_email"
                value={form.shipping_email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email"
                className={getInputClass('shipping_email')}
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_email ? 'opacity-30' : 'opacity-0'}`}>Email</span>
            </div>
            <div className='min-w-[50%] flex-1 relative'>
              <input
                type="text"
                name="shipping_delivery_notes"
                value={form.shipping_delivery_notes}
                onChange={handleChange}
                placeholder="Delivery Notes"
                className={getInputClass('shipping_delivery_notes')}
              />
              <span className={`overlay label absolute right-4 top-0 bottom-0 m-auto h-min text-xs transition-all ease-kibu duration-100 ${form.shipping_delivery_notes ? 'opacity-30' : 'opacity-0'}`}>Delivery Notes</span>
            </div>
          </div>
          <div className="flex justify-between gap-2 mt-6 3xl:mt-12">
            <button
              onClick={handleCancel}
              className="bg-black p-4 px-8 rounded-full text-white text-lg shrink"
            >
              <X strokeWidth={3} className='w-4 h-4' />
            </button>
            <button
              type="submit"
              className={`bg-orange p-4 px-8 rounded-full w-full flex justify-center text-white text-lg ${!isFormValid ? 'opacity-30 cursor-not-allowed' : ''}`}
              disabled={!isFormValid || stripeCheckoutActive}
            >
              {stripeCheckoutActive 
                ? 
                  <div className='w-min h-min animate-spin'>
                    <LoaderPinwheel className='h-4 w-4' />
                  </div> 
                :
                  (toPay > 0 ? 'Pay via Stripe' : 'Finish')}
            </button>
          </div>
        </form>
        </div>
      </div>
  );
};

export default ShippingForm;
