import React, { useState, useEffect, useRef } from 'react';
import useOrderStore from '@/context/useOrderStore'

const InteractiveImage = ({ parts, className, setPartIndexHovered, setPartIndexClicked, partClicked, setMousePosition }) => {
  const [hoveredPart, setHoveredPart] = useState(null);
  const [clickedPart, setClickedPart] = useState(null);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const loadedImagesRef = useRef([]);
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const partCanvasesRef = useRef([]);

  const { colourPickerIntroduced, setColourPickerIntroduced } = useOrderStore()


  useEffect(() => {
    
    if (!parts) return
    const canvas = canvasRef.current;
    const container = containerRef.current;

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    };

    Promise.all(parts.map(part => loadImage(part.src)))
      .then((images, ogPartIndex) => {
        loadedImagesRef.current = images;
        partCanvasesRef.current = images.map((img, index) => {
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = img.width;
          tempCanvas.height = img.height;
          tempCanvas.index = ogPartIndex
          const tempCtx = tempCanvas.getContext('2d');
          tempCtx.drawImage(img, 0, 0);
          return tempCanvas;
        });

        if (images.length > 0) {
          updateCanvasSize();
        }
      })
      .catch((error) => {
        
      })
      .finally(() => {
        
        drawAllParts()
        setImagesLoaded(true)
      })

    const updateCanvasSize = () => {
      const containerWidth = container.offsetWidth;
      const aspectRatio = loadedImagesRef.current[0].width / loadedImagesRef.current[0].height;
      canvas.width = containerWidth;
      canvas.height = containerWidth / aspectRatio;
    };

    const handleResize = () => {
      updateCanvasSize();
      drawAllParts();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const deselect = () => {
    partClicked(null)
    setPartIndexClicked(null)
  }

  const handleOutsideClick = (e) => {
    
    if (!(e.target instanceof HTMLCanvasElement)) {
      deselect()
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
      deselect()
    };
  }, [])

  const drawAllParts = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    loadedImagesRef.current.forEach((img, index) => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    });    
  };

  const detectPart = (x, y) => {
    const ALPHA_THRESHOLD = 10;
    for (let i = parts.length - 1; i >= 0; i--) {
      const partCanvas = partCanvasesRef.current[i];
      const partCtx = partCanvas.getContext('2d');
      const scaleX = partCanvas.width / canvasRef.current.width;
      const scaleY = partCanvas.height / canvasRef.current.height;
      const partPixelData = partCtx.getImageData(x * scaleX, y * scaleY, 1, 1).data;
      if (partPixelData[3] >= ALPHA_THRESHOLD) {
        return parts[i]
      }
    }
    return null;
  };

  const handleCanvasClick = (event) => {
    
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x_screen = event.clientX
    const y_screen = event.clientY

    const x = x_screen - rect.left;
    const y = y_screen - rect.top;
    
    setMousePosition({x: x_screen, y: y_screen})
    

    const detectedPart = detectPart(x, y)
    if (detectedPart?.part_name === 'cushion_left' 
      || detectedPart?.part_name === 'cushion_right') {
        return
      }
    setClickedPart(detectedPart);
    
    if (!detectedPart) {
      setPartIndexClicked(null)
    } else {
      setPartIndexClicked(detectedPart.index);
    }
    partClicked(detectedPart?.index, {x: x_screen, y: y_screen})
  };

  const handleCanvasOver = (event) => {
    
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x_screen = event.clientX
    const y_screen = event.clientY

    const x = x_screen - rect.left;
    const y = y_screen - rect.top;

    setMousePosition({x: x_screen, y: y_screen})

    const detectedPart = detectPart(x, y);
    if (!detectedPart) {
      setHoveredPart(null)
      setPartIndexHovered(null)
      return
    } else if (detectedPart.part_name === 'cushion_left' 
      || detectedPart.part_name === 'cushion_right' || detectedPart.part_name === 'cover') {
        setHoveredPart(null)
        setPartIndexHovered(null)
        return
      }
    setHoveredPart(detectedPart);
    setPartIndexHovered(detectedPart.index);
  };

  const triggerClickOnPart = (partIndex) => {
    
    const partCanvas = partCanvasesRef.current[partIndex];
    if (!partCanvas) return;

    const canvas = canvasRef.current;
    
    const rect = canvas.getBoundingClientRect();
  
    const partCtx = partCanvas.getContext('2d');
    const ALPHA_THRESHOLD = 10;
    let clickX, clickY;
  
    // Scan for a non-transparent pixel
    outerLoop: for (let y = 0; y < partCanvas.height; y++) {
      for (let x = 0; x < partCanvas.width; x++) {
        const partPixelData = partCtx.getImageData(x, y, 1, 1).data;
        if (partPixelData[3] >= ALPHA_THRESHOLD) {
          clickX = x;
          clickY = y;
          break outerLoop;
        }
      }
    }
    
  
    if (clickX === undefined || clickY === undefined) {
      return;
    }

    partCtx.fillStyle = 'red';
    partCtx.beginPath();
    partCtx.arc(clickX, clickY, 5, 0, 2 * Math.PI);
    partCtx.fill();
  
    // Scale to the main canvas dimensions
    const scaleX = canvas.width / partCanvas.width;
    const scaleY = canvas.height / partCanvas.height;
  
    const scaledClickX = clickX * scaleX + rect.left;
    const scaledClickY = clickY * scaleY + rect.top;
  
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: scaledClickX,
      clientY: scaledClickY
    });
  
    canvas.dispatchEvent(clickEvent);
  };
  
  useEffect(() => {
    if (partCanvasesRef.current.length !== parts.length) return
    const clickOn = 3;
    
    if (imagesLoaded && partCanvasesRef.current.length === parts.length && canvasRef.current && !colourPickerIntroduced) {
      triggerClickOnPart(clickOn)
      setColourPickerIntroduced(true)
    }
  }, [imagesLoaded, partCanvasesRef?.current, canvasRef])

  return (
    <div ref={containerRef} className={`interactive-image absolute w-full h-auto ${className} border border-red `}>
      <canvas
        ref={canvasRef}
        onClick={handleCanvasClick}
        onMouseMove={handleCanvasOver}
        onMouseLeave={() => setHoveredPart(null)}
        className={`w-full h-auto border border-red ${hoveredPart ? 'cursor-pointer' :''}`}
      />
    </div>
  );
};

export default InteractiveImage;
