import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const OrderStatus = () => {
  const { orderId } = useParams();
  const [statusHistory, setStatusHistory] = useState([]);
  const [animateLastLine, setAnimateLastLine] = useState(false);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await fetch('https://swbytn6553.execute-api.eu-west-2.amazonaws.com/prod/orders/' + orderId);

        if (!response.ok) {
          console.error('Failed to fetch order data');
          return;
        }

        const data = await response.json();
        console.log("Fetched order data:", data);

        const extractedStatusHistory = data.status_history || [];
        console.log("Extracted Status History:", extractedStatusHistory);
        setStatusHistory(extractedStatusHistory);
      } catch (error) {
        console.error('Error fetching order data:', error);
      }
    };

    // If statusHistory isn't already available, fetch it
    fetchOrderData();

  }, [orderId]);

  useEffect(() => {
    if (statusHistory.length > 0) {
      // Trigger the animation when the last status is completed
      setAnimateLastLine(true);
    }
  }, [statusHistory]);

  const STATUS_ORDER = [
    "ordered",
    "configured",
    "pre-production",
    "production",
    "dispatched",
    "delivered"
  ];

  const STATUS_LABELS = {
    "ordered": "Ordered",
    "configured": "Configured",
    "pre-production": "Pre-Production",
    "production": "Production",
    "dispatched": "Dispatched",
    "delivered": "Delivered"
  };

  // Filter statusHistory to remove statuses without labels
  const filteredStatusHistory = statusHistory.filter(entry => STATUS_LABELS.hasOwnProperty(entry.status));

  const sortedStatusHistory = filteredStatusHistory.sort((a, b) => {
    const dateA = a.dateTime?.$date || a.timestamp?.$date;
    const dateB = b.dateTime?.$date || b.timestamp?.$date;
    return new Date(dateA) - new Date(dateB);
  });

  const statusMap = new Map();
  sortedStatusHistory.forEach(entry => {
    console.log(statusHistory)
    console.log(filteredStatusHistory)
    if (!statusMap.has(entry.status)) {
      statusMap.set(entry.status, entry.dateTime?.$date || entry.timestamp?.$date);
    }
  });

  const lastCompletedStatus = sortedStatusHistory[sortedStatusHistory.length - 1]?.status;
  const lastCompletedIndex = STATUS_ORDER.indexOf(lastCompletedStatus);

  const completedStatuses = STATUS_ORDER.filter((status, index) => {
    return index <= lastCompletedIndex;
  });

  return (
    <div className="w-screen h-screen flex flex-col items-center bg-background relative">
      <div className="absolute top-8 left-8 w-20">
        <div className="w-1.6 h-16 bg-contain bg-no-repeat bg-logo-orange"></div>
      </div>
      <div className="flex flex-col items-center justify-center flex-1">
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-start justify-center">
            {STATUS_ORDER.map((status, index) => {
              const isCompleted = completedStatuses.includes(status);
              const statusDate = statusMap.get(status);
              const isLastCompleted = index === lastCompletedIndex;

              return (
                <div key={status} className="flex flex-col items-start">
                  <div className="flex">
                    <div className="flex flex-col items-center justify-center">
                      <div className={`w-8 h-8 rounded-full ${isCompleted ? 'bg-orange' : 'bg-grey'} z-10`} />
                      {index < STATUS_ORDER.length - 1 && (
                        <div className={`relative w-1.5 h-16 -mt-1`}>
                          <div className={`absolute top-0 w-1.5 h-16 bg-grey`} />
                          <div className={`absolute top-0 w-1.5 ${isLastCompleted ? (animateLastLine ? 'h-8' : 'h-0') : 'h-20'} ${isCompleted ? 'bg-orange' : 'bg-grey'} ${isLastCompleted ? 'rounded-b-full transition-all ease-out duration-2000' : ''}`} />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col ml-8 h-6">
                      <div className={`text-2xl h-10 ${isCompleted ? 'text-orange' : 'text-grey'}`}>
                        {STATUS_LABELS[status]}
                      </div>
                      <div className="text-xs">
                        {isCompleted ? (statusDate ? new Date(statusDate).toLocaleString() : "Not Available") : "Not Available"}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <a className='underline text-xs mb-10' href='mailto:hello@kibu.family'>Contact Us</a>
    </div>
  );
};

export default OrderStatus;
