import { v4 as uuidv4 } from 'uuid';

export const getAddonStatesForItem = (orderItem, addonIds) => {
  const addonStates = {
    colourState: '',
    headbandState: '',
    engravingState: '',
    caseState: '',
    prosoundState: '',
    kibuCareState: '',
  }

  // Handle colour state
  const customColoursAddon = orderItem.addons?.find(addon => addon.product_id.$oid === addonIds.custom_colours);
  if (customColoursAddon && Object.values(customColoursAddon.custom_colours).every(value => value === null)) {
    addonStates.colourState = 'required';
  } else if (orderItem.product_id.$oid === "6637dd0c02a2b22450ef3188" && orderItem.colour == null) {
    addonStates.colourState = 'required';
  } else if (orderItem.colours === null && orderItem.product_id.$oid !== "6637dd0c02a2b22450ef3188") {
    addonStates.colourState = 'required';
  } else {
    addonStates.colourState = 'active';

  }

  // Handle headband state
  const headbandAddon = orderItem.addons?.find(addon => addon.product_id.$oid === addonIds.headband);
  if (!headbandAddon) {
    addonStates.headbandState = 'inactive';
  } else if (headbandAddon.size === null) {
    addonStates.headbandState = 'required';
  } else {
    addonStates.headbandState = 'active';
  }

  // Handle engraving state
  const engravingAddon = orderItem.addons?.find(addon => addon.product_id.$oid === addonIds.engraving);
  if (!engravingAddon) {
    addonStates.engravingState = 'inactive';
  } else if (engravingAddon.engraving === null) {
    addonStates.engravingState = 'required';
  } else {
    addonStates.engravingState = 'active';
  }

  // Handle case state
  const caseAddon = orderItem.addons?.find(addon => addon.product_id.$oid === addonIds.case);
  if (!caseAddon) {
    addonStates.caseState = 'inactive';
  } else if (!caseAddon.colour) {
    addonStates.caseState = 'required';
  } else {
    addonStates.caseState = 'active';
  }

  // Handle prosound state
  const prosoundAddon = orderItem.addons?.find(addon => addon.product_id.$oid === addonIds.prosound);
  if (!prosoundAddon) {
    addonStates.prosoundState = 'inactive';
  } else {
    addonStates.prosoundState = 'active';
  }

  // Handle kibu care state
  const kibucareAddon = orderItem.addons?.find(addon => {
    return addon.product_id.$oid === addonIds.kibu_care_1 || addon.product_id.$oid === addonIds.kibu_care_2;
  });

  if (!kibucareAddon) {
    addonStates.kibuCareState = 'inactive';
  } else {
    addonStates.kibuCareState = 'active';
  }

  return addonStates;
}

export const generateUid = () => {
  return uuidv4().replace(/-/g, '').substring(0, 24);
}
