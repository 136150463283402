import React from 'react'

const Colour = ({className}) => (
  <svg className={className} width="48" height="52" viewBox="0 0 24 26" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2ZM9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 10C18.866 10 22 13.134 22 17C22 20.866 18.866 24 15 24C11.134 24 8 20.866 8 17C8 13.134 11.134 10 15 10ZM15 8C19.9706 8 24 12.0294 24 17C24 21.9706 19.9706 26 15 26C10.0294 26 6 21.9706 6 17C6 12.0294 10.0294 8 15 8Z"/>
  </svg>
)

const Case = ({className}) => (
  <svg className={className} width="70" height="28" viewBox="0 0 35 14" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M28.2758 2H6.72427C5.38013 2 4.20005 2.89405 3.83624 4.18802L2.71162 8.18802C2.17358 10.1017 3.61178 12 5.59965 12H29.4004C31.3882 12 32.8264 10.1017 32.2884 8.18802L31.1638 4.18802C30.8 2.89405 29.6199 2 28.2758 2ZM6.72427 0C4.48404 0 2.51724 1.49008 1.91089 3.64669L0.786273 7.6467C-0.110458 10.8362 2.28653 14 5.59965 14H29.4004C32.7135 14 35.1105 10.8362 34.2137 7.6467L33.0891 3.6467C32.4828 1.49009 30.516 0 28.2758 0H6.72427Z"/>
  </svg>
)

const Engraving = ({className}) => (
  <svg className={className} width="66" height="28" viewBox="0 0 33 14" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2079 3.17239C10.8821 3.17239 10.6089 3.06205 10.3882 2.84135C10.1675 2.62065 10.0571 2.34741 10.0571 2.02162C10.0571 1.69583 10.1675 1.42259 10.3882 1.20189C10.6089 0.981198 10.8821 0.87085 11.2079 0.87085C11.5232 0.87085 11.7912 0.981198 12.0119 1.20189C12.2326 1.42259 12.3429 1.69583 12.3429 2.02162C12.3429 2.34741 12.2326 2.62065 12.0119 2.84135C11.7912 3.06205 11.5232 3.17239 11.2079 3.17239ZM12.0907 4.32317V13.0091H10.2936V4.32317H12.0907Z"/>
    <path d="M6.35549 13.0092L2.11497 8.1066V13.0092H0.317871V2.05322H2.11497V7.05041L6.37125 2.05322H8.6255L3.86477 7.5391L8.70432 13.0092H6.35549Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.1623 4.27022C19.8779 4.21092 19.5818 4.18127 19.2737 4.18127C18.6222 4.18127 18.0284 4.31789 17.4924 4.59113C16.9669 4.85387 16.5518 5.19542 16.247 5.6158V1.34375H14.45V13.0091H16.247V11.748C16.5518 12.1579 16.9617 12.4942 17.4766 12.7569C18.0021 13.0196 18.6011 13.151 19.2737 13.151C19.5812 13.151 19.8774 13.1198 20.1623 13.0573V11.2493C19.7657 11.4767 19.3382 11.5904 18.8796 11.5904C18.4277 11.5904 17.9969 11.4748 17.587 11.2435C17.1876 11.0123 16.8618 10.676 16.6096 10.2346C16.3679 9.78275 16.247 9.25728 16.247 8.65825C16.247 8.05922 16.3679 7.539 16.6096 7.09761C16.8618 6.65622 17.1876 6.31992 17.587 6.08872C17.9969 5.85751 18.4277 5.74191 18.8796 5.74191C19.3382 5.74191 19.7657 5.85558 20.1623 6.08294V4.27022Z" />
    <path opacity="0.3" d="M16.247 5.6158C16.5518 5.19542 16.9669 4.85387 17.4924 4.59113C18.0284 4.31789 18.6222 4.18127 19.2737 4.18127C20.0409 4.18127 20.7345 4.36518 21.3546 4.73301C21.9746 5.10084 22.4633 5.6263 22.8206 6.30941C23.178 6.98201 23.3566 7.75445 23.3566 8.62672C23.3566 9.499 23.178 10.2819 22.8206 10.9756C22.4633 11.6587 21.9694 12.1946 21.3388 12.5835C20.7188 12.9618 20.0304 13.151 19.2737 13.151C18.6011 13.151 18.0021 13.0196 17.4766 12.7569C16.9617 12.4942 16.5518 12.1579 16.247 11.748V13.0091H14.45V1.34375H16.247V5.6158ZM21.528 8.62672C21.528 8.02769 21.4019 7.51273 21.1497 7.08185C20.9079 6.64046 20.5821 6.30941 20.1723 6.08872C19.7729 5.85751 19.342 5.74191 18.8796 5.74191C18.4277 5.74191 17.9969 5.85751 17.587 6.08872C17.1876 6.31992 16.8618 6.65622 16.6096 7.09761C16.3679 7.539 16.247 8.05922 16.247 8.65825C16.247 9.25728 16.3679 9.78275 16.6096 10.2346C16.8618 10.676 17.1876 11.0123 17.587 11.2435C17.9969 11.4748 18.4277 11.5904 18.8796 11.5904C19.342 11.5904 19.7729 11.4748 20.1723 11.2435C20.5821 11.0018 20.9079 10.655 21.1497 10.2031C21.4019 9.75122 21.528 9.22575 21.528 8.62672Z" />
    <path opacity="0.5" d="M33.0002 4.32324V13.0092H31.2031V11.9845C30.9194 12.3419 30.5463 12.6256 30.0839 12.8358C29.632 13.0355 29.1486 13.1353 28.6336 13.1353C27.9505 13.1353 27.3357 12.9934 26.7892 12.7097C26.2532 12.4259 25.8276 12.0056 25.5123 11.4486C25.2076 10.8916 25.0552 10.219 25.0552 9.43078V4.32324H26.8365V9.16279C26.8365 9.94048 27.0309 10.5395 27.4198 10.9599C27.8086 11.3698 28.3393 11.5747 29.0119 11.5747C29.6845 11.5747 30.2153 11.3698 30.6041 10.9599C31.0035 10.5395 31.2031 9.94048 31.2031 9.16279V4.32324H33.0002Z" />
  </svg>
)

const Headband = ({className}) => (
  <svg className={className} width="60" height="58" viewBox="0 0 30 29" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.91097 2.32098C9.76385 1.08272 12.1686 0.311768 15.2579 0.311768V2.31177C12.5162 2.31177 10.5084 2.99067 9.02223 3.98383C7.53018 4.98096 6.50263 6.33621 5.79359 7.77883C4.3612 10.6932 4.2808 13.8561 4.2808 14.7229C4.2808 15.9454 3.679 16.9505 3.23997 17.6577C3.19569 17.729 3.15325 17.7968 3.11261 17.8618C2.93184 18.1509 2.7865 18.3833 2.67161 18.6117C2.55357 18.8464 2.51182 18.9958 2.50229 19.0918C2.51028 19.1172 2.53086 19.1812 2.58025 19.2935C2.66559 19.4876 2.79931 19.744 2.98091 20.0579C3.34259 20.683 3.85304 21.4655 4.43333 22.308C5.59136 23.9893 6.98576 25.8495 7.91607 27.0482L6.33608 28.2744C5.39104 27.0567 3.97239 25.1646 2.78622 23.4425C2.19441 22.5832 1.64998 21.7512 1.24979 21.0595C1.05046 20.715 0.87651 20.3876 0.749408 20.0985C0.640958 19.8518 0.5 19.4914 0.5 19.1355C0.5 18.5895 0.688753 18.103 0.884886 17.7131C1.04584 17.3931 1.25233 17.0636 1.43472 16.7725C1.47124 16.7142 1.5068 16.6575 1.54083 16.6027C1.99221 15.8757 2.2808 15.3128 2.2808 14.7229C2.2808 13.7774 2.358 10.2348 3.99867 6.89664C4.82617 5.21301 6.06402 3.55528 7.91097 2.32098Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.589 2.32098C20.7362 1.08272 18.3314 0.311768 15.2421 0.311768V2.31177C17.9838 2.31177 19.9916 2.99067 21.4778 3.98383C22.9698 4.98096 23.9974 6.33621 24.7064 7.77883C26.1388 10.6932 26.2192 13.8561 26.2192 14.7229C26.2192 15.9454 26.821 16.9505 27.26 17.6577C27.3043 17.729 27.3467 17.7968 27.3874 17.8618C27.5682 18.1509 27.7135 18.3833 27.8284 18.6117C27.9464 18.8464 27.9882 18.9958 27.9977 19.0918C27.9897 19.1172 27.9691 19.1812 27.9198 19.2935C27.8344 19.4876 27.7007 19.744 27.5191 20.0579C27.1574 20.683 26.647 21.4655 26.0667 22.308C24.9086 23.9893 23.5142 25.8495 22.5839 27.0482L24.1639 28.2744C25.109 27.0567 26.5276 25.1646 27.7138 23.4425C28.3056 22.5832 28.85 21.7512 29.2502 21.0595C29.4495 20.715 29.6235 20.3876 29.7506 20.0985C29.859 19.8518 30 19.4914 30 19.1355C30 18.5895 29.8112 18.103 29.6151 17.7131C29.4542 17.3931 29.2477 17.0636 29.0653 16.7725C29.0288 16.7142 28.9932 16.6575 28.9592 16.6027C28.5078 15.8757 28.2192 15.3128 28.2192 14.7229C28.2192 13.7774 28.142 10.2348 26.5013 6.89664C25.6738 5.21301 24.436 3.55528 22.589 2.32098Z" />
  </svg>

)

const Kibucare = ({className}) => (
  <svg className={className} width="38" height="54" viewBox="0 0 19 27" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.887 9.37988L17.4634 7.80352C18.2657 7.00236 18.7617 5.89477 18.7617 4.67147C18.7617 2.22606 16.7791 0.24351 14.3337 0.24351C13.1104 0.24351 12.0028 0.739442 11.2017 1.54179L9.90632 2.83712V0.24292H1.0498V9.09885H3.64341L2.34513 10.3971C1.54515 11.1983 1.0498 12.3047 1.0498 13.5268C1.0498 15.9722 3.03235 17.9548 5.47777 17.9548C6.70107 17.9548 7.80865 17.4588 8.60982 16.6565L10.1862 15.0801C10.8155 16.7592 12.4344 17.9548 14.3331 17.9548C16.7785 17.9548 18.7611 15.9722 18.7611 13.5268C18.7611 11.6281 17.5655 10.0086 15.8864 9.37988H15.887Z"/>
    <path d="M16.9858 26.1036C15.7196 26.1036 14.8188 25.1604 14.8188 23.8687C14.8188 22.5855 15.7111 21.6338 16.9773 21.6338C18.133 21.6338 18.9998 22.4411 18.9998 23.7157C18.9998 23.8432 18.9913 23.9877 18.9743 24.1151H15.8301C15.8981 24.812 16.3484 25.2538 16.9858 25.2538C17.4447 25.2538 17.8441 25.0159 17.946 24.693H18.9488C18.8043 25.5088 17.997 26.1036 16.9858 26.1036ZM15.8641 23.4013H17.9715C17.9205 22.8575 17.5466 22.4836 16.9943 22.4836C16.4419 22.4836 15.9915 22.8575 15.8641 23.4013Z" />
    <path d="M11.8433 26.0017V21.7358H12.693L12.744 22.3306H12.795C13.016 21.9228 13.4833 21.6848 14.0357 21.6848C14.1377 21.6848 14.2311 21.6933 14.3161 21.7103V22.6961C14.1971 22.6791 14.1377 22.6706 14.0272 22.6706C13.2454 22.6706 12.812 23.1804 12.812 24.0727V26.0017H11.8433Z" />
    <path d="M8.46844 26.1036C7.63565 26.1036 7.0918 25.6023 7.0918 24.8629C7.0918 23.9877 7.8396 23.4863 9.18225 23.3843L9.68362 23.3418V23.2144C9.68362 22.764 9.42019 22.4751 8.96131 22.4751C8.55342 22.4751 8.2475 22.696 8.17951 23.0699H7.18527C7.22776 22.2371 7.97557 21.6338 8.96981 21.6338C9.99804 21.6338 10.6524 22.2796 10.6524 23.2909V24.9904C10.6524 25.2029 10.7628 25.3133 10.9498 25.3133C11.0008 25.3133 11.0263 25.3048 11.0943 25.2963L11.1707 26.0017C11.0433 26.0441 10.8733 26.0696 10.7288 26.0696C10.253 26.0696 9.93006 25.8232 9.83658 25.4153H9.78559C9.48817 25.8657 9.02929 26.1036 8.46844 26.1036ZM8.10303 24.7865C8.10303 25.0669 8.29848 25.2623 8.65539 25.2623C9.24174 25.2623 9.68362 24.7865 9.68362 24.0896V24.0302L9.21624 24.0642C8.52792 24.1151 8.10303 24.3701 8.10303 24.7865Z" />
    <path d="M3.79433 26.1037C2.06078 26.1037 0.871094 24.812 0.871094 23.036C0.871094 21.2769 2.08628 19.9513 3.79433 19.9513C5.13698 19.9513 6.2332 20.7671 6.43714 21.9568H5.39192C5.15398 21.2515 4.55913 20.8776 3.79433 20.8776C2.64713 20.8776 1.90782 21.7188 1.90782 23.0275C1.90782 24.2512 2.59614 25.1774 3.79433 25.1774C4.55063 25.1774 5.17097 24.8205 5.40041 24.0982H6.44564C6.25019 25.3049 5.15398 26.1037 3.79433 26.1037Z" />
  </svg> 
)

const Sound = ({className}) => (
  <svg className={className} width="58" height="50" viewBox="0 0 29 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 9.177C0 8.67649 0.405742 8.27075 0.90625 8.27075V8.27075C1.40676 8.27075 1.8125 8.67649 1.8125 9.177V15.8228C1.8125 16.3233 1.40676 16.7291 0.90625 16.7291V16.7291C0.405742 16.7291 0 16.3233 0 15.8228V9.177Z"/>
    <path d="M21.1455 9.177C21.1455 8.67649 21.5512 8.27075 22.0518 8.27075V8.27075C22.5523 8.27075 22.958 8.67649 22.958 9.177V15.8228C22.958 16.3233 22.5523 16.7291 22.0518 16.7291V16.7291C21.5512 16.7291 21.1455 16.3233 21.1455 15.8228V9.177Z" />
    <path d="M3.02051 5.25C3.02051 4.74949 3.42625 4.34375 3.92676 4.34375V4.34375C4.42727 4.34375 4.83301 4.74949 4.83301 5.25V19.75C4.83301 20.2505 4.42727 20.6562 3.92676 20.6562V20.6562C3.42625 20.6562 3.02051 20.2505 3.02051 19.75V5.25Z" />
    <path d="M6.04199 1.02075C6.04199 0.520244 6.44773 0.114502 6.94824 0.114502V0.114502C7.44875 0.114502 7.85449 0.520244 7.85449 1.02075V23.9791C7.85449 24.4796 7.44875 24.8853 6.94824 24.8853V24.8853C6.44773 24.8853 6.04199 24.4796 6.04199 23.9791V1.02075Z" />
    <path d="M9.0625 7.0625C9.0625 6.56199 9.46824 6.15625 9.96875 6.15625V6.15625C10.4693 6.15625 10.875 6.56199 10.875 7.0625V17.9375C10.875 18.438 10.4693 18.8438 9.96875 18.8438V18.8438C9.46824 18.8438 9.0625 18.438 9.0625 17.9375V7.0625Z" />
    <path d="M12.083 9.479C12.083 8.9785 12.4887 8.57275 12.9893 8.57275V8.57275C13.4898 8.57275 13.8955 8.9785 13.8955 9.479V15.5207C13.8955 16.0212 13.4898 16.4269 12.9893 16.4269V16.4269C12.4887 16.4269 12.083 16.0212 12.083 15.5207V9.479Z" />
    <path d="M27.1875 9.479C27.1875 8.9785 27.5932 8.57275 28.0938 8.57275V8.57275C28.5943 8.57275 29 8.9785 29 9.479V15.5207C29 16.0212 28.5943 16.4269 28.0938 16.4269V16.4269C27.5932 16.4269 27.1875 16.0212 27.1875 15.5207V9.479Z" />
    <path d="M15.1045 5.25C15.1045 4.74949 15.5102 4.34375 16.0107 4.34375V4.34375C16.5113 4.34375 16.917 4.74949 16.917 5.25V19.75C16.917 20.2505 16.5113 20.6562 16.0107 20.6562V20.6562C15.5102 20.6562 15.1045 20.2505 15.1045 19.75V5.25Z" />
    <path d="M18.125 3.4375C18.125 2.93699 18.5307 2.53125 19.0312 2.53125V2.53125C19.5318 2.53125 19.9375 2.93699 19.9375 3.4375V21.5625C19.9375 22.063 19.5318 22.4688 19.0312 22.4688V22.4688C18.5307 22.4688 18.125 22.063 18.125 21.5625V3.4375Z" />
    <path d="M24.167 7.0625C24.167 6.56199 24.5727 6.15625 25.0732 6.15625V6.15625C25.5738 6.15625 25.9795 6.56199 25.9795 7.0625V17.9375C25.9795 18.438 25.5738 18.8438 25.0732 18.8438V18.8438C24.5727 18.8438 24.167 18.438 24.167 17.9375V7.0625Z" />
  </svg>
)

const Icons = {
  Colour,
  Case,
  Engraving,
  Headband,
  Kibucare,
  Sound
}

export default Icons
