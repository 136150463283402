import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useOrderStore from '@/context/useOrderStore' // Import the order store

const OrderNumber = () => {
  const navigate = useNavigate()
  const [orderNumber, setOrderNumber] = useState('')
  const { createNewOrder } = useOrderStore() // Get the createNewOrder function from the store

  const gotoOrder = () => {
    navigate('/' + orderNumber)
  }

  const handleCreateNewOrder = async () => {
    const newOrderId = await createNewOrder() // Create a new order and get its ID
    navigate('/' + newOrderId) // Navigate to the new order page
  }

  return (
    <div className='relative order-number flex gap-4 flex-col justify-center items-center h-[100dvh] p-12'>
      <button className='text-center px-4 md:px-8 py-2 md:py-4 text-base md:text-lg rounded-full bg-orange text-white disabled:opacity-30'
        onClick={handleCreateNewOrder}>
        Create New Order
      </button>
      {/* <input className='px-4 w-80 md:px-8 py-2 md:py-4 text-base text-center md:text-lg border-2 border-grey/30 bg-transparent rounded-full text-lg focus:border-orange focus:outline-none'
        type='text'
        placeholder='Your Order Number'
        value={orderNumber}
        maxLength={30}
        onChange={(e) => setOrderNumber(e.target.value)} />
      <button className='text-center px-4 md:px-8 py-2 md:py-4 text-base md:text-lg rounded-full bg-orange text-white disabled:opacity-30'
        onClick={gotoOrder} disabled={!orderNumber}>
        Find Order
      </button> */}
      <div className='opacity-30 text-center'>
        Already ordered? Look for the E-Mail we sent you for a direct link :)
      </div>
    </div>
  )
}

export default OrderNumber