import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

const SoundWaveAnimation = ({ x, y, colourHex, onRemove }) => {
  const size = 600
  const strokeWidth = 4
  const colour = colourHex
  const waveAnimation = useAnimation();

  useEffect(() => {
    waveAnimation.start({
      scale: [0.2, 1],
      opacity: [0, 1, 0],
      filter: ['blur(10px)', 'blur(5px)', 'blur(0px)'],
      transition: { duration: 1.1, ease: [0.25, 0.46, 0.45, 0.94] }, // cubic-bezier for easeOutQuad
    });

    const timeout = setTimeout(() => {
      if (onRemove) {
        onRemove();
      }
    }, 1100); // Match this duration with the animation duration

    return () => clearTimeout(timeout);
  }, [waveAnimation]);

  return (
    <div className="container opacity-90 fixed flex justify-center items-center z-0" style={{ left: x, top: y, width: size + 'px', height: size + 'px' }}>
      <svg className="wave relative pointer-events-none" width={size} height={size} viewBox={`0 0 ${size} ${size}`} style={{ left: -size/2, top: -size/2 }}>
        <motion.circle
          cx={size/2}
          cy={size/2}
          r={size/2.1}
          fill="none"
          stroke={colour}
          strokeWidth={strokeWidth}
          // filter="url(#turbulence)"
          style={{transform: 'translate3d(0, 0, 0)'}}
          animate={waveAnimation}
        />
        <motion.circle
          cx={size/2}
          cy={size/2}
          r={size/3}
          fill="none"
          stroke={colour}
          strokeWidth={strokeWidth}
          // filter="url(#turbulence)"
          animate={waveAnimation}
          style={{transform: 'translate3d(0, 0, 0)'}}
          transition={{ duration: 3, delay: 0.5 }}
        />
        <motion.circle
          cx={size/2}
          cy={size/2}
          r={size/4}
          fill="none"
          stroke={colour}
          strokeWidth="2"
          // filter="url(#turbulence)"
          animate={waveAnimation}
          style={{transform: 'translate3d(0, 0, 0)'}}
          transition={{ duration: 4, delay: 1 }}
        />
      </svg>
    </div>
  );
};

export default SoundWaveAnimation;
