import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft, X } from 'lucide-react';
import useOrderStore from '@/context/useOrderStore';
import { generateUid } from '@/helpers/helpers';

const Engraving = ({ className, orderItem, setEngravingText }) => {
  const navigate = useNavigate();
  const { activeOrderItemId, orderId } = useParams();
  const { order, updateOrderItem, addonIds, recalculatePay } = useOrderStore();
  const [engravingAddon, setEngravingAddon] = useState(null);
  const [engraving, setEngraving] = useState('');

  useEffect(() => {
    if (!orderItem) return;
    setEngravingAddon(orderItem.addons?.find(addon => addon.product_id.$oid === addonIds.engraving));
  }, [orderItem]);

  useEffect(() => {
    if (!orderItem) return;
    if (engravingAddon) {
      setEngraving(engravingAddon.engraving);
    } else {
      return;
    }
  }, [engravingAddon]);

  useEffect(() => {
    setEngravingText(engraving);
  }, [engraving]);

  const createEngravingAddon = () => {
    if (order?.addons?.find(addon => addon.product_id.$oid === addonIds.engraving)) return;
    const engravingAddon = {
      engraving: null,
      item_uid: {
        $oid: generateUid()
      },
      product_id: {
        $oid: addonIds.engraving
      },
      protected: false,
      type: 'Engraving'
    };
    return engravingAddon;
  };

  const updateEngraving = () => {
    let engravingAddon = orderItem.addons.find(addon => addon.product_id.$oid === addonIds.engraving);
    if (!engravingAddon) {
      engravingAddon = createEngravingAddon();
    }

    const updatedEngravingAddon = {
      ...engravingAddon,
      engraving: engraving
    };

    const updatedAddons = orderItem.addons.filter(addon => addon.product_id.$oid !== addonIds.engraving);
    updatedAddons.push(updatedEngravingAddon);
    const updatedOrderItem = {
      ...orderItem,
      addons: updatedAddons
    };
    updateOrderItem(updatedOrderItem);
    recalculatePay();
    goBack();
  };

  const deleteEngraving = () => {
    const updatedAddons = orderItem.addons.filter(addon => addon.product_id.$oid !== addonIds.engraving);
    const updatedOrderItem = {
      ...orderItem,
      addons: updatedAddons
    };
    updateOrderItem(updatedOrderItem);
    recalculatePay();
    goBack();
  };

  const goBack = () => {
    navigate('/' + orderId + '/' + activeOrderItemId);
  };

  const handleBackClick = () => {
    if (!engravingAddon?.protected && engraving === '') {
      deleteEngraving();
    }
    goBack();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Regular expression to filter out emojis
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FC00}-\u{1FFFD}]/gu;
    const filteredValue = value.replace(emojiRegex, '');
    setEngraving(filteredValue);
  };

  return (
    <div className={`edit flex flex-col h-full z-20 gap-4 md:gap-6 items-center justify-center flex-1 ${className}`}>
      <h2 className='text-lg hidden md:block md:text-3xl text-center'>What do you want it to say?</h2>
      <input className='px-4 md:px-8 py-2 md:py-4 text-base md:text-lg border-2 border-grey/30 bg-transparent rounded-full text-lg focus:border-orange focus:outline-none'
        type='text'
        placeholder='Maybe a name?'
        value={engraving}
        maxLength={10}
        onChange={handleInputChange} />
      <div className='flex gap-1'>
        <button className='text-center px-4 md:px-8 py-2 md:py-4 text-base md:text-lg rounded-full border border-2 border-orange text-orange transition-all ease-kibu duration-200 hover:bg-orange hover:text-white' onClick={handleBackClick}>
          <ArrowLeft strokeWidth={3} className='h-4 w-4' />
        </button>
        <button className='text-center px-4 md:px-8 py-2 md:py-4 text-base md:text-lg rounded-full bg-orange text-white disabled:opacity-30' onClick={updateEngraving} disabled={!engraving}>
          Save
        </button>
        {engravingAddon?.engraving && !engravingAddon.protected ?
          <button className='text-center px-4 md:px-8 py-2 md:py-4 text-base md:text-lg rounded-full bg-red text-white' onClick={deleteEngraving}>
            <X className='h-4 w-4' />
          </button>
          : null
        }
      </div>
    </div>
  );
};

export default Engraving;