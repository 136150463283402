import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { CheckCircle2, Pencil, Plus, X } from 'lucide-react';
import _ from 'lodash';
import useOrderStore from '@/context/useOrderStore';
import { getAddonStatesForItem } from '@/helpers/helpers';
import DynamicHeadphones from './DynamicHeadphones';
import DynamicCover from './DynamicCover';

const Nav = ({ finishOrderClicked, order, updatingOrder, saveOrderClicked, headphonesInteractive, toggleCover, createNewHeadphone, createNewCover }) => {
	const { activeOrderItemId, orderId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [showNotification, setShowNotification] = useState(false);
	const { toPay, recalculatePay, addonIds, addOrderItem, removeOrderItem, untouchedOrder } = useOrderStore();
	const [jumpToItem, setJumpToItem] = useState(null);
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [showCircles, setShowCircles] = useState(false); // State to manage circle visibility
	const [circlePosition, setCirclePosition] = useState({ top: 0, left: 0 }); // State to store the position of the plus button
	const navRef = useRef(null); // Ref for the nav element
	const plusButtonRef = useRef(null); // Ref for the plus button

	const handleItemSelect = (id) => {
		navigate('/' + orderId + '/' + id);
		toggleCover(false, null);
	};

	const itemNeedsAttention = (index) => {
		const orderItem = order.items[index];
		const addonStates = getAddonStatesForItem(orderItem, addonIds);
		for (let key in addonStates) {
			if (addonStates[key] === 'required') {
				return true;
			}
		}
		return false;
	};

	useEffect(() => {
		if (!order?.items) return;
		outerLoop:
		for (const orderItem of order.items) {
			const addonStates = getAddonStatesForItem(orderItem, addonIds);
			for (let key in addonStates) {
				if (addonStates[key] === 'required') {
					setJumpToItem(orderItem.item_uid.$oid);
					break outerLoop;
				} else {
					setJumpToItem(null);
				}
			}
		}
	}, [order]);

	useEffect(() => {
		if (!order && !untouchedOrder) return;

		setUnsavedChanges(!_.isEqual(order, untouchedOrder));
	}, [order, untouchedOrder]);

	useEffect(() => {
		if (toPay > 0) {
			setShowNotification(true);
			const timer = setTimeout(() => setShowNotification(false), 2000);
			return () => clearTimeout(timer);
		}
	}, [toPay]);

	useEffect(() => {
		const navElement = navRef.current;
		const fullHeight = navElement.scrollHeight + 10;

		const isMobile = window.innerWidth < 768; // Check if screen width is below md breakpoint

		if (isMobile) {
			if (headphonesInteractive) {
				// Animate out by setting max-height to 0
				navElement.style.visibility = 'visible'; // Ensure it's visible during animation
				navElement.style.maxHeight = `${fullHeight}px`; // Set to current height for transition
				requestAnimationFrame(() => {
					navElement.style.maxHeight = '0px';
				});

				// Set visibility to hidden after the transition ends
				navElement.addEventListener('transitionend', () => {
					navElement.style.visibility = 'hidden';
				}, { once: true });

			} else {
				// Reset max-height and visibility to animate back in
				navElement.style.visibility = 'visible'; // Make it visible immediately
				navElement.style.maxHeight = '0px'; // Start from collapsed height
				requestAnimationFrame(() => {
					navElement.style.transition = ''; // Re-enable transition
					navElement.style.maxHeight = `${fullHeight}px`; // Animate back to full height
				});

				// Remove the max-height after the animation completes
				navElement.addEventListener('transitionend', () => {
					navElement.style.maxHeight = ''; // Reset to auto after transition
				}, { once: true });
			}
		}
	}, [headphonesInteractive]);

	const renderRemoveButton = (item) => {
		const isSingleItem = order.items.length === 1;

		return (
			<div className={`absolute z-20 bottom-2 p-2 shadow-button right-2 bg-black text-white cursor-pointer rounded-full
        transition-all duration-500 ease-kibu hover:rotate-180 hover:shadow-button-hover
        ${item.protected ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'}`}
				onClick={() => {
					console.log("Remove button clicked for item:", item);
					removeOrderItem(item);
					recalculatePay();
				}}>
				<X strokeWidth='3' className='h-3 w-3' />
			</div>
		);
	};

	const handlePlusClick = () => {
		if (!showCircles) {
			const rect = plusButtonRef.current.getBoundingClientRect();
			setCirclePosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
		}
		setShowCircles(!showCircles);
	};

	return (
		<div ref={navRef} className={`nav relative flex flex-col z-30 md:flex-row gap-4 w-full pt-2 pb-0 pr-2 pl-2 md:p-4 h-auto md:h-40 md:min-h-40 transition-all ease-kibu duration-500 overflow-visible ${headphonesInteractive ? 'opacity-0 pointer-events-none md:transform-gpu md:opacity-100 md:blur-sm md:-translate-y-24' : 'md:transform-gpu'}`}>
			<div className='relative rounded-l w-full grow flex items-center min-h-24 md:min-h-28 group/bar'>
				<div className='flex justify-center items-center aspect-square logo p-2 md:p-8 pl-10 md:pl-14 md:mr-8 z-10'>
					<div className='min-w-12 max-w-8 aspect-square bg-contain bg-no-repeat bg-logo-white'></div>
				</div>
				<div className='headphones-container flex-1 text-white flex items-center overflow-hidden gap-3 h-full z-10'>
					<div className='hidden md:block'>
						Your Order ({order?.items?.length})
					</div>
					<div className='flex no-scrollbar items-center flex-1 p-2 md:p-3 gap-3 h-full overflow-x-scroll overflow-y-visible'>
						{order?.items?.map((item, index) => {
							const selected = item.item_uid.$oid === activeOrderItemId;
							const colourAddon = item.addons?.find(addon => addon.product_id.$oid === addonIds.custom_colours);
							const isHeadphones = item.product_id.$oid === addonIds.headphones;
							const isCase = item.product_id.$oid === addonIds.case;

							return (
								<div
									key={index}
									className={`headphone group/item flex justify-center items-center pointer-events-auto relative h-full aspect-square shadow-button rounded-l border border-2 transition-all ease-kibu hover:border-offwhite/70 hover:shadow-button-hover 
                ${selected ? 'border-offwhite pointer-events-none shadow-button-selected scale-105 duration-200' : 'border-transparent cursor-pointer scale-90 duration-75 hover:scale-100'}`}
									onClick={() => handleItemSelect(item.item_uid.$oid)}
								>
									<div className='headphones-container absolute left-0 right-0 w-full h-full p-2 transition-all ease-kibu duration-500 group-hover/item:scale-110 flex items-center justify-center'>
										{isHeadphones ? (
											<DynamicHeadphones
												miniature
												orderItem={item}
												viewName="front_view"
												custom_colours={colourAddon?.custom_colours}
												colourway_name={item.colours}
												className='h-full w-4 drop-shadow-xl -mt-3'
											/>
										) : isCase ? (
											<DynamicCover
												type={"miniature"}
												orderItem={item}
												className='w-full drop-shadow-xl'
											/>
										) : null}
									</div>

									<div className={`addons-booked absolute bottom-1 right-2 flex gap-1 items-center flex-wrap text-xxs  ${selected ? 'opacity-100' : 'opacity-70'} hidden`}
										style={{ textShadow: '0px 0px 15px black' }}>
										+{item.addons?.length} addons
									</div>

									<div className={`relative edit rounded-full bg-white opacity-0 text-sm w-min text-black 
                px-3 py-2 transition-all duration-200 ease-kibu
                ${selected ? '' : 'group-hover/item:opacity-100'}`}>
										<Pencil className='w-4 h-4' />
									</div>

									<div className={`absolute top-2 right-2 attention rounded-full w-2 h-2 bg-yellow animate-pulse
                ${itemNeedsAttention(index) ? '' : 'hidden'}`}>
									</div>

									<div className={`absolute top-0 left-0 w-full z-20 h-full flex justify-center items-center group-hover/item:opacity-0
                ${itemNeedsAttention(index) ? 'hidden' : 'block'}`}>
										<CheckCircle2 className='h-10 w-10 drop-shadow-lg'></CheckCircle2>
									</div>

									{renderRemoveButton(item)}
								</div>
							);
						})}
						<div className='relative'>
							<div
								ref={plusButtonRef}
								className={`add-headphone flex justify-center group/add items-center rounded-full border border-2 border-white/50 p-4 transition-all ease-kibu pointer-events-auto cursor-pointer hover:border-white hover:bg-white
									${order?.items?.length === 0 ? 'animate-scaleLoop border-white/100' : ''}`}
								onClick={handlePlusClick}>
								<Plus className='h-6 w-6 transition-all ease-kibu duration-300 scale-75 group-hover/add:scale-100 group-hover/add:text-orange' />
							</div>
						</div>
					</div>
				</div>
				<div className='absolute left-0 top-0 w-full h-full bg-orange rounded-l z-0 scale-100 transition-all duration-200 ease-kibu delay-500 
          group-hover/bar:rounded-lpro group-hover/bar:duration-700 group-hover/bar:delay-75'></div>
			</div>
			<div className={`bg-orange fixed z-20 md:z-auto left-[5%] md:left-auto bottom-8 md:bottom-auto md:w-auto w-[90%] md:relative flex justify-center rounded-l shrink text-nowrap p-4 md:p-8 text-white flex transition-all ease-kibu 
        duration-500 items-center cursor-pointer hover:rounded-xl hover:shadow-button-hover hover:brightness-105 hover:scale-110`}
				onClick={() => jumpToItem ? handleItemSelect(jumpToItem) : finishOrderClicked()}
				style={{ transform: 'none' }}>
				{jumpToItem ?
					<div className='relative flex items-center gap-1'>
						<div className='attention rounded-full w-2 h-2 bg-yellow animate-pulse'></div>
						Please configure <span className='opacity-100'>{toPay > 0 ? '(+£' + toPay + ')' : ''}</span>
					</div>
					:
					<div className='relative flex items-center gap-1'>
						Finish {toPay > 0 ? ' and Pay £' + toPay : ' Order'}
					</div>
				}
				<div className={`absolute top-4 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 text-xs transition-opacity duration-200 ${!showNotification ? 'opacity-0' : 'opacity-100'}`}>
					Payment Updated
				</div>
			</div>
			<div className={`just-save fixed md:absolute cursor-pointer z-20 right-1 md:right-0 bottom-16 md:-bottom-11 py-2 px-3 m-4 w-content text-white text-xxs bg-blue text-center rounded-xl pointer-events-auto
          transition-all ease-kibu hover:scale-105
          ${updatingOrder ? 'brightness-50' : 'brightness-100'}
          ${unsavedChanges ? 'opacity-100' : 'opacity-0'}`}
				onClick={saveOrderClicked}>
				Save and continue later ...
			</div>

			{/* Circles appearing outside of the container */}
			{showCircles && (
				<div
					style={{ position: 'fixed', top: circlePosition.top, left: circlePosition.left - 18 }}
					className="flex flex-col items-center gap-4 z-10 mt-10"
				>
					<div
						className='addItem1 flex justify-center items-center shadow-button rounded-l bg-white h-24 w-24 transition-all ease-kibu pointer-events-auto cursor-pointer hover:border-gray-400 hover:bg-white hover:scale-105'
						onClick={() => { createNewHeadphone(); setShowCircles(false) }}>
						<DynamicHeadphones
							miniature
							viewName="front_view"
							colourway_name={"arctic_blue"}
							className='h-full w-4 -top-1 md:-top-2 drop-shadow-xl'
						/>
					</div>
					<div
						className='addItem2 flex justify-center items-center shadow-button rounded-l bg-white h-24 w-24 transition-all ease-kibu pointer-events-auto cursor-pointer hover:border-gray-400 hover:bg-white hover:scale-105'
						onClick={() => { createNewCover(); setShowCircles(false) }}>
						<DynamicCover
							miniature
							className='h-full w-4 top-0 md:top-0 drop-shadow-xl'
						/>
						{/* Add your action here for the second circle */}

					</div>
				</div>
			)}
		</div>
	)
}

export default Nav;
