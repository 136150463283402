import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ArrowLeft, X } from 'lucide-react'
import { motion } from 'framer-motion'

import useOrderStore from '@/context/useOrderStore'
import DynamicHeadphones from './DynamicHeadphones';
import ColourPicker from './ColourPicker';
import { metaData } from '../assets/headphone-parts-image_meta-data';
import { generateUid } from '@/helpers/helpers';

import SoundWaveAnimation from './SoundWaveAnimation';

const Colour = ({ className, orderItem, showColourPicker, colourPickerPos, colourPickerPartName }) => {
  const navigate = useNavigate();
  const { activeOrderItemId, orderId } = useParams();
  const { order, updateOrderItem, addonIds, recalculatePay, updateOrderParts } = useOrderStore();
  const [selectedColourWay, setSelectedColourWay] = useState(null);
  const [customColourAddon, setCustomColourAddon] = useState(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


  const [lastTriggeredColour, setLastTriggeredColour] = useState('');
  const [animations, setAnimations] = useState([]);

  const addAnimation = (x, y) => {
    const newAnimation = { id: Date.now() * Math.random(), x, y };
    setAnimations([...animations, newAnimation]);
  };

  const removeAnimation = (id) => {
    setAnimations(animations.filter(animation => animation.id !== id));
  };

  const startAnimation = (mousePos) => {
    addAnimation(mousePos?.x, mousePos?.y);
  };

  // Override colourWays and colours when the product_id matches
  const targetProductId = "6637dd0c02a2b22450ef3188";
  const customColours = ['pistachio', 'peach', 'arctic'];
  const colourMap = {
    pistachio: '#CCDEA7',
    peach: '#EEC9B4',
    arctic: '#BAD1D8'
  };

  let colourWays = orderItem.product_id.$oid === targetProductId
    ? customColours
    : metaData.colours.standard_colourways.map(colourway => colourway.colourway_name);

  if (orderItem.product_id.$oid !== targetProductId) {
    colourWays.push('custom_colours');
  }

  // Filter out any unexpected values from the colourWays array
  colourWays = colourWays.filter(colourWay => colourWay && colourWay !== 'none');

  useEffect(() => {
    if (!orderItem) return;

    const addons = orderItem.addons || [];

    // Set a default colourway if none exists
    const initialColourWay = orderItem.colours || 'none';
    setSelectedColourWay(initialColourWay);
    setCustomColourAddon(addons.find(addon => addon.product_id.$oid === addonIds.custom_colours));
  }, [orderItem]);

  useEffect(() => {
    console.log('selectedColourWay', selectedColourWay);
  }, [selectedColourWay]);

  const extractPartsFromColourway = (colourway) => {
    const { colourway_name, ...parts } = colourway;
    return Object.entries(parts).reduce((acc, [partName, partDetails]) => {
      const partData = metaData.views[0].front_view[partName];
      acc[partName] = {
        selected_colour: partDetails.selected_colour,
        material: partDetails.material,
        part_id: partData?.part_id || null
      };
      return acc;
    }, {});
  };

  const createDefaultParts = () => {
    const pearlColor = {
      selected_colour: "pearl",
      material: "66d174c9782dff9b65045c19"
    };
    const headbandCushionColor = {
      selected_colour: "charcoal",
      material: "66d1764a782dff9b65045c1e"
    };

    return {
      headband: pearlColor,
      headband_cushion: headbandCushionColor,
      middle_donut_left: pearlColor,
      middle_donut_right: pearlColor,
      outer_donut_left: pearlColor,
      outer_donut_right: pearlColor,
      inner_left: pearlColor,
      inner_right: pearlColor,
      clip_left: pearlColor,
      clip_right: pearlColor,
    };
  };

  const createUpdatedParts = (customColours) => {
    const defaultParts = createDefaultParts();
    return Object.entries(customColours).reduce((acc, [partName, colourValue]) => {
      if (colourValue) {
        const partData = metaData.views[0].front_view[partName];
        acc[partName] = {
          selected_colour: colourValue.name,
          material: colourValue.material,
          part_id: partData?.part_id || null
        };
      }
      return acc;
    }, defaultParts);
  };

  const updateColourWay = (colourWay) => {
    const targetProductId = "6637dd0c02a2b22450ef3188"; // Cover product ID

    if (orderItem.product_id.$oid === targetProductId) {
      const selectedColour = metaData.colours.available_case_colours.find(colour => colour.name === colourWay);
      const coverPart = metaData.views[0].front_view.cover;

      if (!selectedColour) {
        console.warn(`Color ${colourWay} not found in available_case_colours`);
        return;
      }

      const updatedOrderItem = {
        ...orderItem,
        colour: colourWay,
        parts: {
          cover: {
            part_id: coverPart.part_id,
            selected_colour: colourWay,
            material: selectedColour.material
          }
        }
      };

      setSelectedColourWay(colourWay);
      updateOrderItem(updatedOrderItem);
      updateOrderParts(orderItem.item_uid.$oid, updatedOrderItem.parts);
      recalculatePay();
    } else {
      const hasCustomColourAddon = orderItem.addons?.find(addon => addon.product_id.$oid === addonIds.custom_colours);
      const addCustomColoursAddon = colourWay === 'custom_colours' && !hasCustomColourAddon;
      const removeCustomColoursAddon = colourWay !== 'custom_colours' && hasCustomColourAddon;

      let updatedAddons = orderItem.addons || [];
      let parts = null;

      if (addCustomColoursAddon) {
        const customColourAddon = createCustomColourAddon();
        updatedAddons = [...updatedAddons, customColourAddon];
        parts = createDefaultParts();
      } else if (removeCustomColoursAddon) {
        updatedAddons = updatedAddons.filter(addon => addon.product_id.$oid !== addonIds.custom_colours);
      } else {
        const selectedColourway = metaData.colours.standard_colourways.find(cw => cw.colourway_name === colourWay);
        parts = selectedColourway ? extractPartsFromColourway(selectedColourway) : createDefaultParts();
      }

      // Check if there's a cover addon
      const coverAddon = updatedAddons.find(addon => addon.product_id.$oid === addonIds.case);
      if (coverAddon) {
        const coverPart = metaData.views[0].front_view.cover;
        const selectedColour = metaData.colours.available_case_colours.find(colour => colour.name === coverAddon.colour);

        if (selectedColour) {
          parts = {
            ...parts,
            cover: {
              part_id: coverPart.part_id,
              selected_colour: coverAddon.colour,
              material: selectedColour.material,
              item_id: orderItem.item_uid.$oid,
              addon_id: coverAddon.item_uid.$oid
            }
          };
        }
      }

      const updatedOrderItem = {
        ...orderItem,
        colours: colourWay,
        addons: updatedAddons,
      };

      setSelectedColourWay(colourWay);
      updateOrderItem(updatedOrderItem);
      updateOrderParts(orderItem.item_uid.$oid, parts);
      recalculatePay();
    }
  };


  const capitalizeWords = (str) => {
    const words = str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()).split(' ');
    const firstPart = words.slice(0, Math.ceil(words.length / 2)).join(' ');
    const secondPart = words.slice(Math.ceil(words.length / 2)).join(' ');
    return [firstPart, secondPart];
  };

  const makeCustomColoursObject = (partName, colourName) => {
    const selectedColour = metaData.colours.available_colours.find(colour => colour.name === colourName);

    const updatedCustomColoursObject = {
      ...customColourAddon.custom_colours,
      [partName]: colourName ? {
        name: colourName,
        material: selectedColour.material
      } : null
    }

    return updatedCustomColoursObject
  }

  const deleteCustomColourAddon = () => {
    const updatedAddons = orderItem.addons.filter(addon => addon.product_id.$oid !== addonIds.custom_colours)
    const updatedOrderItem = {
      ...orderItem,
      addons: updatedAddons
    }
    updateOrderItem(updatedOrderItem)
    recalculatePay()
  }

  const goBack = () => {
    if (selectedColourWay === 'custom_colours' && customColourAddon && !anyPartColored()) {
      alert("Please select a colour for at least one part before going back.");
      return;
    }
    if (customColourAddon && orderItem.colours !== 'custom_colours' && !customColourAddon.protected) {
      deleteCustomColourAddon();
    }
    navigate('/' + orderId + '/' + activeOrderItemId);
  }

  const createCustomColourAddon = () => ({
    item_uid: { $oid: generateUid() },
    product_id: { $oid: addonIds.custom_colours },
    protected: false,
    type: "Custom Colour",
    custom_colours: {
      clip_left: null,
      clip_right: null,
      headband: null,
      inner_left: null,
      inner_right: null,
      middle_donut_left: null,
      middle_donut_right: null,
      outer_donut_left: null,
      outer_donut_right: null
    }
  });

  const updateCustomColours = (customColours, selectedColourName) => {
    const selectedColour = metaData.colours.available_colours.find(colour => colour.name === selectedColourName);


    const updatedColourAddon = {
      ...orderItem.addons?.find(addon => addon.product_id.$oid === addonIds.custom_colours),
      custom_colours: Object.fromEntries(
        Object.entries(customColours).map(([partName, colourValue]) => {
          if (colourValue) {
            const partData = metaData.views[0].front_view[partName];
            const colourName = typeof colourValue === 'string' ? colourValue : colourValue.name;
            return [
              partName,
              {
                name: colourName,
                material: selectedColour.material,
                part_id: partData?.part_id || null
              }
            ];
          }
          return [partName, null];
        })
      )
    };

    const updatedAddons = orderItem.addons?.filter(addon => addon.product_id.$oid !== addonIds.custom_colours) || [];
    updatedAddons.push(updatedColourAddon);

    const updatedParts = createUpdatedParts(updatedColourAddon.custom_colours);

    const updatedOrderItem = {
      ...orderItem,
      addons: updatedAddons,
      colours: 'custom_colours'
    };

    setLastTriggeredColour(selectedColour?.hex);
    setSelectedColourWay('custom_colours');
    setCustomColourAddon(updatedColourAddon);
    updateOrderItem(updatedOrderItem);
    updateOrderParts(orderItem.item_uid.$oid, updatedParts);
    startAnimation(colourPickerPos);

  };

  const anyPartColored = () => {
    if (!customColourAddon) return false;
    return Object.values(customColourAddon.custom_colours).some(colour => colour !== null && colour.name !== undefined);
  }


  return (
    <div className={`flex items-center flex-1 w-full h-full ${className}`}>
      {animations.map((animation, index) => (
        <SoundWaveAnimation
          key={animation.id}
          x={animation.x}
          y={animation.y}
          colourHex={lastTriggeredColour}
          onRemove={() => removeAnimation(animation.id)}
        />
      ))}
      {customColourAddon &&
        <div className={`flex fixed w-min h-0 min-w-min ${isMobile ? 'bottom-20 left-1/2 transform -translate-x-1/2 translate-y-full md:translate-x-none' : 'translate-x-4 md:translate-x-none'} items-center justify-center z-50 transition-all ease-kibu duration-100`}
          style={!isMobile ? {
            left: colourPickerPos.x,
            top: colourPickerPos.y
          } : {}}>
          <ColourPicker
            selectedColour={customColourAddon.custom_colours[colourPickerPartName]?.name || null}
            setSelectedColour={(colourName) => updateCustomColours(makeCustomColoursObject(colourPickerPartName, colourName), colourName)}
            className={`ok ${showColourPicker ? 'opacity-100 scale-100 blur-none' : 'opacity-0 blur-sm scale-90 pointer-events-none'}`}
          />
        </div>
      }

      <motion.div
        key='colour-settings'
        initial={{ opacity: 0, filter: "blur(30px)", transform: "scale(0.98)" }}
        animate={{ opacity: 1, filter: "blur(0px)", transform: "scale(1)" }}
        exit={{ opacity: 0, filter: "blur(30px)", transform: "scale(0.98)" }}
        className='edit flex flex-col gap-2 md:gap-6 items-center justify-center h-full w-full'>
        <h2 className='text-lg md:text-3xl text-center'>
          {customColourAddon?.protected ? 'Choose your colour' : 'Choose your colour way'}
        </h2>
        <div className='relative z-30 flex gap-1 flex-wrap justify-center max-w-[90%]'>
          <button className={`text-center px-1 md:px-4 py-2 md:py-3 text-base md:text-lg rounded-full 
            border border-2 border-orange text-orange transition-all ease-kibu duration-200 hover:bg-orange hover:text-white
            h-14 md:h-24 flex items-center justify-center
            `}
            onClick={goBack}>
            <ArrowLeft strokeWidth={3} className='h-4 w-4' />
          </button>
          {customColourAddon?.protected
            ? null
            : colourWays.map((colourWay, index) => {
              const [firstPart, secondPart] = capitalizeWords(colourWay);
              return (
                <div key={index} className="text-center flex flex-col items-center ml-1 mr-1 md:mr-2 md:ml-2">
                  <div className="relative">
                    {colourWay === 'custom_colours' && (
                      <div className="absolute top-0 right-0 transform -translate-y-1/2 bg-orange
                  text-white rounded-full px-1.5 py-0.5 text-xs z-20">
                        +£15
                      </div>
                    )}
                    <button
                      className={`relative w-14 h-14 md:w-24 md:h-24 outline-orange cursor-pointer overflow-hidden flex justify-center items-center text-sm md:text-lg rounded-lg transition-all ease-kibu duration-200 
                    ${(orderItem.product_id.$oid === targetProductId
                          ? colourWay === orderItem.colour
                          : colourWay === selectedColourWay)
                          ? 'outline outline-offset-2 outline-orange'
                          : 'opacity-60 '
                        } hover:opacity-100 `}
                      onClick={() => updateColourWay(colourWay)}
                      style={{
                        borderRadius: '15px',
                        border: 'none',
                        backgroundColor:
                          orderItem.product_id.$oid === targetProductId
                            ? colourMap[colourWay]
                            : 'transparent',
                      }}
                    >
                      {orderItem.product_id.$oid !== targetProductId ? (
                        <img
                          src={`/assets/colour_ways/${colourWay}.jpg`}
                          className="absolute inset-0 w-full h-full object-cover transition-transform duration-200 ease-in-out transform hover:scale-110 z-1"
                          alt={colourWay.replace('_', ' ')}
                        />
                      ) : null}
                    </button>

                  </div>
                  <div className="mt-2 text-black text-xs md:text-sm md:w-24 break-words" style={{ height: '2.5rem' }}>
                    <div>{firstPart}</div>
                    <div>{secondPart}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </motion.div>
    </div>
  )
}

export default Colour;
