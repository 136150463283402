import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion'

import ProductSettingToggle from './ProductSettingToggle'
import Icons from '../assets/SettingIcons'
import useOrderStore from '@/context/useOrderStore'
import { getAddonStatesForItem } from '../helpers/helpers';
import { metaData } from '../assets/headphone-parts-image_meta-data';


const generateUid = () => {
  return uuidv4().replace(/-/g, '').substring(0, 24)
}

const ProductSettings = ({ className, orderItem, toggleCover, cover = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId, activeOrderItemId } = useParams();
  const { updateOrderItem, productData, recalculatePay, addonIds, updateOrderParts } = useOrderStore();

  const [colourState, setColourState] = useState('inactive');
  const [colourProtected, setCustomColourProtected] = useState(false);
  const [headbandState, setHeadbandState] = useState('inactive');
  const [headbandProtected, setHeadbandProtected] = useState(false);
  const [engravingState, setEngravingState] = useState('inactive');
  const [engravingText, setEngravingText] = useState(null);
  const [engravingProtected, setEngravingProtected] = useState(false);
  const [caseState, setCaseState] = useState('inactive');
  const [caseProtected, setCaseProtected] = useState(false);
  const [kibucareState, setKibucareState] = useState('inactive');
  const [kibucareProtected, setKibucareProtected] = useState(false);
  const [prosoundState, setProsoundState] = useState('inactive');

  const [caseColour, setCaseColour] = useState(null);
  const caseAvailableColours = metaData.colours.available_case_colours;
  const [isExtraHeadbandAdultSize, setIsExtraHeadbandAdultSize] = useState(false);
  const [kibucareDuration, setKibucareDuration] = useState(0);

  useEffect(() => {
  }, [colourState])

  useEffect(() => {
    if (!orderItem) return;

    if (orderItem.product_id.$oid === "6637dd0c02a2b22450ef3188") {
      const addonStates = getAddonStatesForItem(orderItem, addonIds);
      setColourState(addonStates.colourState);
    }

    if (!orderItem.addons) return;


    const addonStates = getAddonStatesForItem(orderItem, addonIds);
    const customColoursAddon = orderItem.addons.find(addon => addon.product_id.$oid === addonIds.custom_colours);
    setCustomColourProtected(customColoursAddon?.protected);
    setColourState(addonStates.colourState);

    const headbandAddon = orderItem.addons.find(addon => addon.product_id.$oid === addonIds.headband);
    setHeadbandProtected(headbandAddon?.protected);
    setIsExtraHeadbandAdultSize(headbandAddon?.headband_size === null ? false : true);
    setHeadbandState(addonStates.headbandState);

    const engravingAddon = orderItem.addons.find(addon => addon.product_id.$oid === addonIds.engraving);
    setEngravingProtected(engravingAddon?.protected);
    setEngravingText(engravingAddon?.engraving);
    setEngravingState(addonStates.engravingState);

    const caseAddon = orderItem.addons.find(addon => addon.product_id.$oid === addonIds.case);
    setCaseProtected(caseAddon?.protected);
    setCaseState(addonStates.caseState);
    if (caseAddon) {
      const colourObjectFromOrderItem = caseAvailableColours.find(colourObject => colourObject.name === caseAddon.colour);
      setCaseColour(colourObjectFromOrderItem);
    }

    const prosoundAddon = orderItem.addons.find(addon => addon.product_id.$oid === addonIds.prosound);
    setProsoundState(addonStates.prosoundState);

    const kibucareAddon = orderItem.addons.find(addon => {
      if (addon.product_id.$oid === addonIds.kibu_care_1) {
        setKibucareDuration(1);
        return true;
      } else if (addon.product_id.$oid === addonIds.kibu_care_2) {
        setKibucareDuration(2);
        return true;
      } else {
        return false;
      }
    });
    setKibucareProtected(kibucareAddon?.protected);
    setKibucareState(addonStates.kibuCareState);
  }, [orderItem]);



  const toggleHeadband = () => {
    const headbandAddon = orderItem.addons.find(addon => addon.product_id?.$oid === addonIds.headband)
    let newAddons

    if (headbandAddon && isExtraHeadbandAdultSize) {
      newAddons = orderItem.addons.filter(addon => addon.product_id.$oid !== addonIds.headband)
      setIsExtraHeadbandAdultSize(false)
    } else if (headbandAddon && !isExtraHeadbandAdultSize) {
      newAddons = orderItem.addons.map(addon =>
        addon.product_id.$oid === addonIds.headband
          ? { ...addon, headband_size: 'adult' }
          : addon
      )
      setIsExtraHeadbandAdultSize(true)
    } else {
      const newHeadbandAddon = {
        item_uid: { $oid: generateUid() },
        product_id: { $oid: addonIds.headband },
        protected: false,
        headband_size: null,
        type: 'Headband',
      }
      newAddons = [...orderItem.addons, newHeadbandAddon]
      setIsExtraHeadbandAdultSize(false)

      // Add headband parts
      const mainHeadphoneParts = orderItem.parts || {}
      const headbandPart = metaData.views[0].front_view.headband
      const headbandCushionPart = metaData.views[0].front_view.headband_cushion
      const newHeadbandParts = {
        headband: {
          part_id: headbandPart.part_id,
          selected_colour: mainHeadphoneParts.headband?.selected_colour || 'default_color',
          material: mainHeadphoneParts.headband?.material || 'default_material',
        },
        headband_cushion: {
          part_id: headbandCushionPart.part_id,
          selected_colour: mainHeadphoneParts.headband_cushion?.selected_colour || 'default_color',
          material: mainHeadphoneParts.headband_cushion?.material || 'default_material',
        }
      }

      updateOrderParts(orderItem.item_uid.$oid, newHeadbandParts, newHeadbandAddon.item_uid.$oid)
    }

    const updatedOrderItem = { ...orderItem, addons: newAddons }
    updateOrderItem(updatedOrderItem)
    recalculatePay()
  }

  const toggleProsound = () => {
    const hasProsound = orderItem.addons.some(addon => addon.product_id?.$oid === addonIds.prosound)
    let newAddons

    if (hasProsound) {
      newAddons = orderItem.addons.filter(addon => addon.product_id.$oid !== addonIds.prosound)
    } else {
      newAddons = [
        ...orderItem.addons,
        {
          item_uid: { $oid: generateUid() },
          product_id: { $oid: addonIds.prosound },
          protected: false,
          type: 'Prosound',
        },
      ]
    }
    const updatedOrderItem = { ...orderItem, addons: newAddons }
    updateOrderItem(updatedOrderItem)
    recalculatePay()
  }

  const toggleKibucare = () => {
    if (kibucareProtected) return
    const has_kibu_care_1 = orderItem.addons.some(addon => addon.product_id?.$oid === addonIds.kibu_care_1)
    const has_kibu_care_2 = orderItem.addons.some(addon => addon.product_id?.$oid === addonIds.kibu_care_2)
    let newAddons


    if (has_kibu_care_2 && kibucareProtected) {
      // newAddons = orderItem.addons.filter(addon => addon.product_id.$oid !== addonIds.kibu_care_2)
      // newAddons = [
      //   ...newAddons,
      //   {
      //     item_uid: { $oid: generateUid() },
      //     product_id: { $oid: addonIds.kibu_care_1 },
      //     protected: kibucareProtected,
      //     duration: 12,
      //     type: 'Kibu Care',
      //   },
      // ]
    } else if (has_kibu_care_1 && kibucareProtected) {

    } else if (has_kibu_care_1) {
      newAddons = orderItem.addons.filter(addon => addon.product_id.$oid !== addonIds.kibu_care_1)
      newAddons = [
        ...newAddons,
        {
          item_uid: { $oid: generateUid() },
          product_id: { $oid: addonIds.kibu_care_2 },
          protected: kibucareProtected,
          duration: 24,
          type: 'Kibu Care',
        },
      ]
    } else if (has_kibu_care_2) {
      newAddons = orderItem.addons.filter(addon => addon.product_id.$oid !== addonIds.kibu_care_2)
    } else {
      newAddons = [
        ...orderItem.addons,
        {
          item_uid: { $oid: generateUid() },
          product_id: { $oid: addonIds.kibu_care_1 },
          protected: kibucareProtected,
          duration: 12,
          type: 'Kibu Care',
        },
      ]
    }
    const updatedOrderItem = { ...orderItem, addons: newAddons }
    updateOrderItem(updatedOrderItem)
    recalculatePay()
  }

  const toggleCase = () => {
    const caseAddon = orderItem.addons.find(addon => addon.product_id?.$oid === addonIds.case)
    let newAddons

    if (!caseAddon) {
      // Add case with first color
      setCaseColour(caseAvailableColours[0])
      toggleCover(true, caseAvailableColours[0])
      const newCaseAddon = {
        colour: caseAvailableColours[0].name,
        item_uid: { $oid: generateUid() },
        product_id: { $oid: addonIds.case },
        protected: false,
        type: 'Cover',
      }
      newAddons = [...orderItem.addons, newCaseAddon]
    } else {
      const colourObjectFromOrderItem = caseAvailableColours.find(colourObject => colourObject.name === caseAddon.colour)
      const selectedCaseColourIndex = caseAvailableColours.indexOf(colourObjectFromOrderItem)

      if (selectedCaseColourIndex === caseAvailableColours.length - 1) {
        // Remove case if we've cycled through all colors
        newAddons = orderItem.addons.filter(addon => addon.product_id.$oid !== addonIds.case)
        setCaseColour(null)
        toggleCover(false, null)
      } else {
        // Move to next color
        const newColour = caseAvailableColours[selectedCaseColourIndex + 1]
        setCaseColour(newColour)
        toggleCover(true, newColour)
        newAddons = orderItem.addons.map(addon =>
          addon.product_id.$oid === addonIds.case ? { ...addon, colour: newColour.name } : addon
        )
      }
    }

    const updatedOrderItem = { ...orderItem, addons: newAddons }
    updateOrderItem(updatedOrderItem)
    recalculatePay()
  }

  const toggleEngraving = () => {
    toggleCover(false, null)
    navigate('/' + orderId + '/' + activeOrderItemId + '/engraving')
  }

  const toggleColour = () => {
    toggleCover(false, null)
    navigate('/' + orderId + '/' + activeOrderItemId + '/colour')
  }


  const renderDefaultSettings = () => (
    <>
      <ProductSettingToggle
        title='Colour'
        state={colourState}
        protected={colourProtected}
        inactiveCta='Click to choose'
        requiredCta='Click to choose'
        activeCta='Click to switch'
        icon={Icons.Colour}
        click={toggleColour} />
      <ProductSettingToggle
        title={(headbandState === 'inactive' || headbandState === 'required') ? '+1 Headband' : (isExtraHeadbandAdultSize ? '+1 Adult Headband' : '+1 Child Headband')}
        state={headbandState}
        protected={headbandProtected}
        inactiveCta='Click to add'
        requiredCta='Click to choose'
        activeCta={isExtraHeadbandAdultSize ? (headbandProtected ? 'Make Children Size' : 'Remove') : 'Make Adult Size'}
        icon={Icons.Headband}
        scaleIconDown={isExtraHeadbandAdultSize ? false : true}
        price={productData?.find(product => product.name.toLowerCase() === 'headband')?.base_price}
        click={toggleHeadband} />
      <ProductSettingToggle
        title='Engraving'
        state={engravingState}
        protected={engravingProtected}
        inactiveCta='Personalise'
        requiredCta='Click to enter text'
        activeCta={engravingText}
        icon={Icons.Engraving}
        price={productData?.find(product => product.name.toLowerCase() === 'engraving')?.base_price}
        click={toggleEngraving} />
      <ProductSettingToggle
        title='Case'
        state={caseState}
        protected={caseProtected}
        inactiveCta='Get Protection'
        requiredCta='Click to choose colour'
        activeCta='Click to change Colour'
        icon={Icons.Case}
        price={productData?.find(product => product.name.toLowerCase() === 'headphone cover')?.base_price}
        bgColour={caseColour}
        click={toggleCase} />
      <ProductSettingToggle
        title={kibucareState === 'inactive' ? 'Get Kibu Care' : kibucareDuration + ' Year Kibu Care'}
        state={kibucareState}
        protected={kibucareProtected}
        inactiveCta='Increase your peace of mind'
        requiredCta='Click to choose'
        activeCta={kibucareProtected ? 'You are covered' : (kibucareDuration === 1 ? `Click to make it 2 (+£${productData?.find(product => product.name.toLowerCase() === 'kibu care (1 year)')?.base_price})` : 'Click to remove')}
        icon={Icons.Kibucare}
        price={productData?.find(product => product.name.toLowerCase() === 'kibu care (1 year)')?.base_price}
        inactive={kibucareProtected}
        click={toggleKibucare} />
      <ProductSettingToggle
        title='Pro Sound'
        state={prosoundState}
        inactiveCta='Improved Sound'
        requiredCta='Add'
        activeCta='Remove'
        icon={Icons.Sound}
        price={productData?.find(product => product.name.toLowerCase() === 'pro sound')?.base_price}
        click={toggleProsound} />
    </>
  );

  const renderCoverSettings = () => (
    <>
      <ProductSettingToggle
        title='Colour'
        state={colourState}
        protected={colourProtected}
        inactiveCta='Click to choose'
        requiredCta='Click to choose'
        activeCta='Click to switch'
        icon={Icons.Colour}
        click={toggleColour} />
    </>
  );

  return (
    <motion.div
      key='main-settings'
      initial={{ opacity: 0, filter: "blur(30px)", transform: "scale(0.98)" }}
      animate={{ opacity: 1, filter: "blur(0px)", transform: "scale(1)" }}
      exit={{ opacity: 0, filter: "blur(30px)", transform: "scale(0.98)" }}
      className={`product-settings no-scrollbar max-w-full overflow-x-scroll overflow-y-visible pl-2 pr-2 pt-2 pb-2 mt-0 md:mt-0 md:p-0 relative min-h-0 md:h-full md:flex-1 ${className}`}>
      <div className='relative max-w-full md:h-full flex items-center justify-start md:justify-center'>
        <div className='settings relative flex md:flex-wrap items-center md:justify-center gap-2 md:gap-4 md:-top-6 2xl:max-w-[1000px]'>
          {cover ? renderCoverSettings() : renderDefaultSettings()}
        </div>
      </div>
    </motion.div>
  );
};

export default ProductSettings;
