import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"

import InteractiveImage from './InteractiveImage';
import { metaData } from '@/assets/headphone-parts-image_meta-data';
import { Circle, MousePointerClick, X } from 'lucide-react';
import ToggleSwitch from './ToggleSwitch';
import useOrderStore from '@/context/useOrderStore'

import Icons from '../assets/SettingIcons'


const DynamicHeadphones = ({ viewName, engraving, colourway_name, custom_colours, toggleColourPicker, interactive, className, miniature, cover, coverColour = "pistachio" }) => {
  const [partsData, setPartsData] = useState([]);
  const [partIndexClicked, setPartIndexClicked] = useState(null)
  const [partIndexHovered, setPartIndexHovered] = useState(null)
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const { order, updateOrderItem } = useOrderStore()
  const { activeOrderItemId } = useParams()
  const [adultHeadband, setAdultHeadband] = useState(false)
  const [coverVisible, setCoverVisible] = useState(false)

  useEffect(() => {
    setCoverVisible(cover)
  }, [cover])


  let isMobile = false;
  const mediaQuery = window.matchMedia('(max-width: 812px)');
  isMobile = mediaQuery.matches;

  const easing = [0.210, 0.035, 0.000, 1.000]

  const toggleAdultHeadband = () => {
    const orderItem = order.items.find(item => item.item_uid.$oid === activeOrderItemId)
    const headband_size = orderItem.headband_size === null ? 'adult' : null

    const updatedOrderItem = { ...orderItem, headband_size: headband_size }
    updateOrderItem(updatedOrderItem)
  }

  useEffect(() => {
    if (!order || !activeOrderItemId) return
    const orderItem = order.items.find(item => item.item_uid.$oid === activeOrderItemId)
    setAdultHeadband(orderItem?.headband_size)
  }, [order, activeOrderItemId])

  const preloadedImages = new Set();

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      if (preloadedImages.has(src)) {
        resolve();
        return;
      }

      const img = new Image();
      img.src = src;
      img.onload = () => {
        preloadedImages.add(src);
        resolve();
      };
      img.onerror = reject;
    });
  };

  const preloadAllImages = async (parts) => {
    const preloadPromises = parts.map(part => preloadImage(part.src));
    await Promise.all(preloadPromises);
  };
  useEffect(() => {
    const view = metaData.views.find(view => view[viewName])?.[viewName];
    const parts = Object.entries(view)
      .filter(([_, part]) => part.active) // Filter out inactive parts
      .sort((a, b) => a[1].index - b[1].index);
    const sortedView = Object.fromEntries(parts);

    const colourWay = colourway_name
      ? metaData.colours.standard_colourways.find(colourWay => colourWay.colourway_name === colourway_name)
      : metaData.colours.standard_colourways.find(colourWay => colourWay.colourway_name === 'none');

    const partsWithImages = Object.entries(sortedView).map(([partName, part]) => {
      if (partName === 'cover' && !cover) {
        return null; // Skip the cover part if cover is not visible
      }

      let imageSrc = `/assets/headphones/${viewName}/${partName}.webp`;

      if (partName === 'cover') {
        imageSrc = `/assets/headphones/${viewName}/${partName}_${coverColour}.webp`;
      } else if (colourWay && colourWay[part.part_name]) {
        imageSrc = `/assets/headphones/${viewName}/${partName}_${colourWay[part.part_name].selected_colour}.webp`;
      } else if (custom_colours) {
        if (custom_colours[partName] === null || custom_colours[partName] === undefined) {
          imageSrc = `/assets/headphones/${viewName}/${partName}_pearl.webp`;
        } else {
          const colorValue = custom_colours[partName];
          const colorName = typeof colorValue === 'string' ? colorValue : colorValue.name;
          imageSrc = `/assets/headphones/${viewName}/${partName}_${colorName}.webp`;
        }
      }
      if (partName === 'cushion_left' || partName === 'cushion_right' || partName === 'cable') {
        imageSrc = `/assets/headphones/${viewName}/${partName}.webp`;
      }

      return {
        part_name: partName,
        index: part.index,
        src: imageSrc
      };
    }).filter(part => part !== null);

    preloadAllImages(partsWithImages).then(() => {
      setPartsData(partsWithImages);
    }).catch(err => {
      console.error("Error preloading images: ", err);
    });
  }, [viewName, colourway_name, custom_colours, coverVisible, coverColour]);

  useEffect(() => {
    if (!interactive) {
      setPartIndexClicked(null)
      setPartIndexHovered(null)
    }
  }, [interactive])


  useEffect(() => {

  }, [partIndexHovered])

  const getPartNameByIndex = (index) => {
    for (const key in partsData) {
      if (partsData[key].index === index) {
        return partsData[key].part_name;
      }
    }
    return null; // Return null if the index is not found
  }

  const handleToggleChange = (isOn) => {

    toggleAdultHeadband()
  };


  return (
    <div className={`dynamic-headphones relative transform-gpu overflow-hidden z-10 w-full flex-1 shrink min-h-0 ${!miniature && '-top-1 md:-top-0'} ${viewName === 'top_view' ? '-top-20 md:top-0' : ''} ${className}`}>
      <AnimatePresence mode='wait'>
        {viewName === 'front_view' ?
          <motion.div
            key='front'
            initial={{ opacity: 0, filter: 'blur(5px)', transform: 'scale(1.05)' }}
            animate={{ opacity: 1, filter: 'blur(0px)', transform: 'scale(1)' }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, filter: 'blur(10px)', transform: 'scale(0.95)' }}
            className={`relative min-h-min w-full transform-gpu left-0 ${!miniature && '-top-12'} md:top-0`}>
            {(!interactive && !miniature) &&
              <motion.div
                key='front'
                initial={{ opacity: 0, filter: 'blur(5px)', transform: 'scale(1.05) translateY(0px)' }}
                animate={{ opacity: 1, filter: 'blur(0px)', transform: 'scale(1) translateY(-40px)' }}
                transition={{ duration: 0.2 }}
                className='absolute w-36 text-xs h-8 text-nowrap -top-12 md:-top-28 left-0 right-0 bottom-0 m-auto z-40'>
                <ToggleSwitch label="Adult Headband" labelOnInfo="Instead of a children's one" vertical={isMobile} value={adultHeadband} onChange={handleToggleChange} />
              </motion.div>
            }
            <AnimatePresence>
              {partsData.map((part, index) => {
                if (part.part_name === 'cover') {
                  return (
                    <motion.img
                      src={part.src}
                      key={index}
                      initial={{ y: '-10%', opacity: 0 }}
                      animate={{
                        y: '0%', opacity: 1,
                        transition: {
                          y: { duration: 2.4, ease: [0.000, 1.000, 0.000, 1.000] },
                          opacity: { duration: 0.8, ease: [0.000, 0.000, 0.000, 1.000] }
                        }
                      }}
                      exit={{ y: '-2%', opacity: 0 }}
                      onAnimationComplete={() => {
                        setCoverVisible(false)
                      }}
                      transition={{ duration: 0.4, ease: [0.000, 0.000, 1.000, 0.000] }}
                      className={`${part.index > 0 ? 'absolute' : 'relative'} min-w-full min-h-full transform-gpu
                        transition-none w-full top-0 left-0 z-50
                        ${(partIndexHovered === null)
                          ? (partIndexClicked === null
                            ? 'brightness-100'
                            : (partIndexClicked === part.index
                              ? 'filter brightness-100'
                              : 'brightness-75'
                            )
                          )
                          : ((partIndexHovered === part.index || partIndexClicked === part.index)
                            ? 'brightness-100 duration-200'
                            : 'brightness-75 duration-700')}
                      `}
                    />
                  );
                } else {
                  return (
                    <img
                      src={part.src}
                      key={index}
                      className={`${part.index > 0 ? 'absolute' : 'relative'} min-w-full min-h-full transform-gpu
                        transition-all ease-kibu w-full top-0 left-0 z-[${part.index}]
                        ${(partIndexHovered === null)
                          ? (partIndexClicked === null
                            ? 'brightness-100'
                            : (partIndexClicked === part.index
                              ? 'filter brightness-100'
                              : 'brightness-75'
                            )
                          )
                          : ((partIndexHovered === part.index || partIndexClicked === part.index)
                            ? 'brightness-100 duration-200'
                            : 'brightness-75 duration-700')}
                        ${(part.part_name === 'cable' && (miniature || interactive)) ? 'opacity-0 duration-100' : 'opacity-100 duration-100'}`}
                    />
                  );
                }
              })}
            </AnimatePresence>
          </motion.div>
          :
          <motion.div
            key='top'
            initial={{ opacity: 0, filter: 'blur(5px)', transform: 'scale(1.05)' }}
            animate={{ opacity: 1, filter: 'blur(0px)', transform: 'scale(1)' }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, filter: 'blur(10px)', transform: 'scale(0.95)' }}
            className={`relative transform-gpu min-h-min w-full md:top-0 right-0 bottom-0 m-auto`}>
            {partsData.map(part => (
              <img src={part.src} className={`${part.index > 0 ? 'absolute' : 'relative'} min-w-full min-h-full ${partIndexHovered === null ? 'brightness-100' : (partIndexHovered === part.index ? 'brightness-100' : 'brightness-75')} transition-all duration-100 ease-kibu w-full top-0 left-0 z-[${part.index}]`} />
            ))}
          </motion.div>
        }
      </AnimatePresence>
      {/* {partsData.map(part => (
        <div className={`absolute min-h-min ${partIndexHovered === null ? 'brightness-100' : (partIndexHovered === part.index ? 'brightness-100' : 'brightness-75')} transition-all duration-100 ease-kibu w-full top-0 left-0 z-[${part.index}]`}>
          <img src={part.src} className='min-w-full min-h-full'/>
        </div>
      ))} */}
      {(viewName === 'front_view' && interactive && colourway_name === 'custom_colours') ?
        <motion.div
          key='top'
          initial={{ opacity: 0, filter: 'blur(0px)', transform: 'scale(1.3)' }}
          animate={{ opacity: 0.7, filter: 'blur(0px)', transform: 'scale(1)' }}
          transition={{ duration: 0.6, ease: easing }}
          exit={{ opacity: 0, filter: 'blur(10px)', transform: 'scale(0.9)' }}
          className={`absolute transform-gpu flex justify-center text-xs md:text-base items-center w-94 h-32 ${!miniature && '-top-14'} md:-top-20 left-0 right-0 bottom-0 m-auto`}>
          {partIndexClicked
            ? <div className='flex flex-col justify-center items-center gap-1 mb-20'>
              <X className='h-6 w-6' />
              Click anywhere to deselect
            </div>
            : <div className='animate-pulse flex flex-col justify-center items-center gap-1 mb-20'>
              <MousePointerClick className='w-6 h-6' />
              Click Part to Choose Colour
            </div>
          }
        </motion.div>
        : null
      }
      {(viewName === 'top_view' && engraving !== '') ?
        <motion.div
          key='top'
          initial={{ opacity: 0, filter: 'blur(0px)', transform: 'scale(1.3)' }}
          animate={{ opacity: 1, filter: 'blur(0px)', transform: 'scale(1)' }}
          transition={{ duration: 0.6, ease: easing }}
          exit={{ opacity: 0, filter: 'blur(10px)', transform: 'scale(0.9)' }}
          className={`absolute transform-gpu flex justify-center items-center w-94 h-32 top-2 md:top-0 left-0 right-0 bottom-0.5 m-auto text-[4cqw] md:text-[2.5cqw]`}
          style={{
            backgroundColor: '#aaaaaa',
            color: 'transparent',
            textShadow: "1px 1px 2px rgba(255,255,255,0.8)",
            backgroundClip: 'text',
            mixBlendMode: 'multiply'
          }}>
          {engraving}
        </motion.div>
        : null
      }
      {(partsData && interactive && colourway_name === 'custom_colours') ?
        <InteractiveImage
          parts={partsData.filter(part => part.part_name !== 'cable')}
          setPartIndexHovered={setPartIndexHovered}
          setPartIndexClicked={setPartIndexClicked}
          setMousePosition={(pos) => setMousePosition(pos)}
          partClicked={(index, mousePos) => {
            toggleColourPicker(getPartNameByIndex(index), mousePos)
          }}
          className={`opacity-0 absolute left-0 ${!miniature && '-top-12'} md:top-0 ${interactive ? 'pointer-events-auto' : 'pointer-events-none'}`} />
        : null
      }
    </div>
  );
};

export default DynamicHeadphones;
