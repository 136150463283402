export const metaData = {
  "views": [
    {
      "front_view": {
        "cable": {
          "part_name": "cable",
          "part_id": null,
          "index": 1,
          "active": true
        },
        "headband": {
          "part_name": "headband",
          "part_id": "66d1a29b782dff9b65045c2c",
          "index": 0,
          "active": true
        },
        "headband_cushion": {
          "part_name": "headband_cushion",
          "part_id": "66d1a27b782dff9b65045c2b",
          "index": 1,
          "active": false
        },
        "middle_donut_left": {
          "part_name": "middle_donut_left",
          "part_id": "66d1a1b0782dff9b65045c28",
          "index": 5,
          "active": true
        },
        "middle_donut_right": {
          "part_name": "middle_donut_right",
          "part_id": "66d1a1b0782dff9b65045c28",
          "index": 4,
          "active": true
        },
        "outer_donut_left": {
          "part_name": "outer_donut_left",
          "part_id": "66d1a0d8782dff9b65045c27",
          "index": 6,
          "active": true
        },
        "outer_donut_right": {
          "part_name": "outer_donut_right",
          "part_id": "66d1a0d8782dff9b65045c27",
          "index": 7,
          "active": true
        },
        "inner_left": {
          "part_name": "inner_left",
          "part_id": "66d1a256782dff9b65045c2a",
          "index": 6,
          "active": false
        },
        "inner_right": {
          "part_name": "inner_right",
          "part_id": "66d1a256782dff9b65045c2a",
          "index": 7,
          "active": false
        },
        "clip_left": {
          "part_name": "clip_left",
          "part_id": "66d1a225782dff9b65045c29",
          "index": 2,
          "active": true
        },
        "clip_right": {
          "part_name": "clip_right",
          "part_id": "66d1a225782dff9b65045c29",
          "index": 3,
          "active": true
        },
        "cushion_left": {
          "part_name": "cushion_left",
          "part_id": "66d1b42a782dff9b65045c2f",
          "index": 8,
          "active": true
        },
        "cushion_right": {
          "part_name": "cushion_right",
          "part_id": "66d1b42a782dff9b65045c2f",
          "index": 9,
          "active": true
        },
        "cover": {
          "part_name": "cover",
          "part_id": "66d1a2c8782dff9b65045c2d",
          "index": 10,
          "active": true
        }
      }
    },
    {
      "top_view": {
        "headband": {
          "part_name": "headband",
          "index": 8,
          "active": true
        },
        // "headband_cushion": {
        //   "part_name": "headband_cushion",
        //   "index": 1,
        //   "active": true
        // },
        "middle_donut_left": {
          "part_name": "middle_donut_left",
          "index": 4,
          "active": true
        },
        "middle_donut_right": {
          "part_name": "middle_donut_right",
          "index": 5,
          "active": true
        },
        "outer_donut_left": {
          "part_name": "outer_donut_left",
          "index": 2,
          "active": true
        },
        "outer_donut_right": {
          "part_name": "outer_donut_right",
          "index": 3,
          "active": true
        },
        // "inner_left": {
        //   "part_name": "inner_left",
        //   "index": 6,
        //   "active": true
        // },
        // "inner_right": {
        //   "part_name": "inner_right",
        //   "index": 7,
        //   "active": true
        // },
        "clip_left": {
          "part_name": "clip_left",
          "index": 0,
          "active": true
        },
        "clip_right": {
          "part_name": "clip_right",
          "index": 1,
          "active": true
        },
        "cushion_left": {
          "part_name": "cushion_left",
          "index": 6,
          "active": true
        },
        "cushion_right": {
          "part_name": "cushion_right",
          "index": 7,
          "active": true
        }
      }
    }
  ],
  "colours": {
    "available_colours": [

      {
        "name": "tangerine",
        "material": "66d175ea782dff9b65045c1d",
        "hex": "#F97C47"
      },
      {
        "name": "buttercup",
        "material": "66d17472782dff9b65045c17",
        "hex": "#E8C553"
      },
      {
        "name": "peach",
        "material": "66d174c9782dff9b65045c19",
        "hex": "#EEC9B4"
      },
      {
        "name": "pearl",
        "material": "66d174c9782dff9b65045c19",
        "hex": "#FAF6FF"
      },
      {
        "name": "pistachio",
        "material": "66d1736f782dff9b65045c16",
        "hex": "#CCDEA7"
      },
      {
        "name": "olive",
        "material": "66d175b9782dff9b65045c1b",
        "hex": "#889881"
      },
      {
        "name": "arctic",
        "material": "66d1758b782dff9b65045c1a",
        "hex": "#BAD1D8"
      },
    ],
    "available_case_colours": [
      {
        "name": "arctic",
        "material": "66d1758b782dff9b65045c1a",
        "hex": "#BAD1D8"
      },
      {
        "name": "pistachio",
        "material": "66d1736f782dff9b65045c16",
        "hex": "#CCDEA7"
      },
      {
        "name": "peach",
        "material": "66d174c9782dff9b65045c19",
        "hex": "#EEC9B4"
      }
    ],
    "standard_colourways": [
      {
        "colourway_name": "none",
        "headband": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "headband_cushion": {
          "selected_colour": "charcoal",
          "material": "66d1764a782dff9b65045c1e"
        },
        "middle_donut_left": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "middle_donut_right": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "outer_donut_left": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "outer_donut_right": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "inner_left": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "inner_right": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "clip_left": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "clip_right": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
      },
      {
        "colourway_name": "forest_green",
        "headband": {
          "selected_colour": "olive",
          "material": "66d175b9782dff9b65045c1b"
        },
        "headband_cushion": {
          "selected_colour": "charcoal",
          "material": "66d1764a782dff9b65045c1e"
        },
        "middle_donut_left": {
          "selected_colour": "pistachio",
          "material": "66d1736f782dff9b65045c16"
        },
        "middle_donut_right": {
          "selected_colour": "pistachio",
          "material": "66d1736f782dff9b65045c16"
        },
        "outer_donut_left": {
          "selected_colour": "pistachio",
          "material": "66d1736f782dff9b65045c16"
        },
        "outer_donut_right": {
          "selected_colour": "pistachio",
          "material": "66d1736f782dff9b65045c16"
        },
        "inner_left": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        },
        "inner_right": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        },
        "clip_left": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        },
        "clip_right": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        }
      },
      {
        "colourway_name": "desert_peach",
        "headband": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "headband_cushion": {
          "selected_colour": "charcoal",
          "material": "66d1764a782dff9b65045c1e"
        },
        "middle_donut_left": {
          "selected_colour": "peach",
          "material": "66d174c9782dff9b65045c19"
        },
        "middle_donut_right": {
          "selected_colour": "peach",
          "material": "66d174c9782dff9b65045c19"
        },
        "outer_donut_left": {
          "selected_colour": "peach",
          "material": "66d174c9782dff9b65045c19"
        },
        "outer_donut_right": {
          "selected_colour": "peach",
          "material": "66d174c9782dff9b65045c19"
        },
        "inner_left": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        },
        "inner_right": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        },
        "clip_left": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        },
        "clip_right": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        }
      },
      {
        "colourway_name": "arctic_blue",
        "headband": {
          "selected_colour": "pearl",
          "material": "66d174c9782dff9b65045c19"
        },
        "headband_cushion": {
          "selected_colour": "charcoal",
          "material": "66d1764a782dff9b65045c1e"
        },
        "middle_donut_left": {
          "selected_colour": "arctic",
          "material": "66d1758b782dff9b65045c1a"
        },
        "middle_donut_right": {
          "selected_colour": "arctic",
          "material": "66d1758b782dff9b65045c1a"
        },
        "outer_donut_left": {
          "selected_colour": "arctic",
          "material": "66d1758b782dff9b65045c1a"
        },
        "outer_donut_right": {
          "selected_colour": "arctic",
          "material": "66d1758b782dff9b65045c1a"
        },
        "inner_left": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        },
        "inner_right": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        },
        "clip_left": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        },
        "clip_right": {
          "selected_colour": "buttercup",
          "material": "66d17472782dff9b65045c17"
        }
      }
    ]
  }
}