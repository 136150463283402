import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const DynamicCover = ({ orderItem, coverColour, type = null, className = "" }) => {
  let effectiveColour = "pearl";

  if (orderItem && orderItem.colour) {
    effectiveColour = orderItem.colour;
  } else if (coverColour) {
    effectiveColour = coverColour;
  }
  const [coverSrc, setCoverSrc] = useState("");

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  useEffect(() => {
    const typePath = type ? `${type}/` : "";
    const imageSrc = `/assets/headphones/front_view/${typePath}cover_${effectiveColour}.webp`;

    // Preload the image
    preloadImage(imageSrc)
      .then(() => setCoverSrc(imageSrc))
      .catch((err) => console.error("Error preloading cover image: ", err));
  }, [effectiveColour, type]);

  return (
    <div className={`dynamic-cover relative transform-gpu z-10 flex w-full flex-1 items-center justify-center shrink min-h-0 ${type !== 'miniature' && '-top-4 md:-top-0'} ${className}`}>
      <AnimatePresence mode="wait">
        <motion.div
          key="cover"
          initial={{ opacity: 0, filter: 'blur(5px)', transform: 'scale(1.05)' }}
          animate={{ opacity: 1, filter: 'blur(0px)', transform: 'scale(0.90)' }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, filter: 'blur(10px)', transform: 'scale(0.95)' }}
          className={`relative w-full h-full flex items-center justify-center ${className}`}
        >
          {coverSrc && (
            <img
              src={coverSrc}
              alt="Headphone Cover"
              className="transform-gpu transition-none z-50 brightness-100"
              style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }}
            />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default DynamicCover;
