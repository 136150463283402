import { useEffect } from 'react';

const useBeforeUnload = (shouldWarn) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldWarn) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message; // Required for most browsers
        return message; // Required for some browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldWarn]);
};

export default useBeforeUnload;
