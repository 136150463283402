import { Route, Routes } from "react-router-dom";
import './App.css';

import Main from '@/views/Main'
import ThankYou from "@/views/ThankYou";
import OrderNumber from "@/views/OrderNumber";
import Success from '@/views/Success'
import Tracking from '@/views/Tracking'
import Delivered from '@/views/Delivered'
import OutForDelivery from '@/views/OutForDelivery'

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<OrderNumber />} />
        {/* <Route path="/success/:orderId" element={<Success />} />
        <Route path="/success" element={<Success />} /> */}
        <Route path="/tracking/:orderId" element={<Tracking />} />
        {/* <Route path="/delivered" element={<Delivered />} />
        <Route path="/outfordelivery" element={<OutForDelivery />} /> */}
        <Route path="/:orderId" element={<Main />} />
        <Route path="/:orderId/:activeOrderItemId" element={<Main />} />
        <Route path="/:orderId/:activeOrderItemId/:component" element={<Main />} />
        <Route path="/thankyou" element={<ThankYou />} />
      </Routes>
    </div>
  );
}

export default App;
