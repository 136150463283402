import React, { useState, useEffect } from 'react';
import { Lock } from 'lucide-react';

const ProductSettingToggle = (props) => {
  let isMobile = false;
  const mediaQuery = window.matchMedia('(max-width: 812px)');
  isMobile = mediaQuery.matches;
  const [state, setState] = useState(props.state);

  useEffect(() => {
    setState(props.state);

  }, [props.state]);


  return (
    <div
      className={`product setting toggle cursor-pointer relative group/item ${props.inactive && 'pointer-events-none'}`}
      onClick={props.click}
    >
      <div
        className={`toggle rounded-full w-28 md:w-40 2xl:w-60 huge:w-80 aspect-square
          flex flex-col gap-1 md:gap-0 p-2 md:p-4 items-center justify-center
          border border-2 transition-all ease-kibu duration-200
          group-hover/item:border-4
          ${state == 'inactive' && 'bg-transparent text-black border-grey border-dotted group-hover/item:bg-orange/5  group-hover/item:border-black/50 group-hover/item:border-3'}
          ${state == 'required' && 'bg-transparent text-orange border-orange group-hover/item:bg-orange/5 animate-scaleLoop'}
          ${state == 'active' && `bg-orange text-white border-orange group-hover/item:md:scale-105`}`}
      >
        <div className='icon aspect-square mb-0 w-6 md:w-8 2xl:w-14 md:mb-2 relative'>
          <props.icon
            className={`w-full h-auto aspect-square transition-all ease-kibu duration-700
            ${state === 'inactive' ? 'fill-black' : state === 'active' ? 'fill-white' : 'fill-orange'}
            ${props.scaleIconDown ? 'scale-[70%] group-hover/item:scale-90' : 'scale-100 group-hover/item:scale-110'}`}
          />
          {props.protected && (
            <Lock
              className={`h-3 w-3 md:h-4 md:w-4 absolute opacity-30 -top-5 right-0 left-0 m-auto md:left-auto md:top-0 md:-right-4 ${state === 'required' ? 'text-black' : ''}`}
            />
          )}
        </div>
        <div className='title flex items-center gap-1 text-sm leading-tight md:leading-none lg:text-lg lg:leading-none xl:leading-tight xl:text-xl text-center'>
          {props.title}
          {(props.bgColour && state === 'active') && (
            <div
              className='flex rounded-full relative top-[1px] bg-black p-1 md:px-2 md:py-1 text-sm items-center gap-1'
              style={{ color: props.bgColour.hex, textTransform: 'capitalize' }}
            >
              <div className='w-3 h-3 rounded-full' style={{ backgroundColor: props.bgColour.hex }}></div>
              <div className='hidden md:block'>{props.bgColour.name}</div>
            </div>
          )}
        </div>
        <div
          className={`hidden md:block cta opacity-50 text-xs text-center mt-1 leading-tight ${state === 'required' && 'opacity-100'}`}
        >
          {state === 'inactive' && props.inactiveCta}
          {state === 'required' && props.requiredCta}
          {state === 'active' && props.activeCta}
        </div>
      </div>
      <div
        className={`price absolute left-[2%] top-[2%] md:left-[5%] md:top-[5%] 2xl:left-[8%] 2xl:top-[8%] flex justify-center items-center w-10 aspect-square bg-background border border-2 border-dotted border-grey border-1 transition-all ease-kibu duration-200 rounded-full text-sm
        group-hover/item:border-black/50 group-hover/item:translate-x-1 group-hover/item:translate-y-1 group-hover/item:border-solid
        ${(props.price && state === 'inactive') ? 'opacity-100 scale-100' : 'opacity-0 scale-75'}`}
      >
        £{props.price}
      </div>
    </div>
  );
};

export default ProductSettingToggle;