import React, { useState, useEffect } from 'react'

const ThankYou = () => {
  return (
    <div className="thank-you w-screen h-screen flex justify-center items-center">
      <div>Thank you. We will produce your headphones and send it through. </div>
    </div>
  )
}

export default ThankYou