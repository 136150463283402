import { create } from 'zustand'
import { metaData } from '@/assets/headphone-parts-image_meta-data';

const useOrderStore = create((set, get) => ({
  productData: undefined,
  order: { items: [] },
  untouchedOrder: undefined,
  colourPickerIntroduced: false,
  stripeCheckoutActive: false,
  addonIds: {
    headphones: '621e2d34b51d4e21d1fa1234',
    case: '6637dd0c02a2b22450ef3188',
    custom_colours: '6644c1946c7d7162c757dffb',
    headband: '6638070084e0b1fd93661e98',
    engraving: '6644c1756c7d7162c757dffa',
    kibu_care_1: '6637db0471b46fdf8071bedb',
    kibu_care_2: '6637dcfb86e20e2f289bf105',
    prosound: '66747151a31a1982a9231dbf'
  },
  toPay: 0,
  kickstarterOrder: { items: [] },
  setColourPickerIntroduced: () => set(() => ({ colourPickerIntroduced: true })),
  setStripeCheckoutActive: (value) => set(() => ({ stripeCheckoutActive: value })),
  setProductData: (productData) => set(() => ({ productData: productData })),
  setOrder: (order) => set(() => ({ order: order })),
  setUntouchedOrder: (order) => set(() => ({ untouchedOrder: order })),
  setKickstarterOrder: (order) => set(() => ({ kickstarterOrder: order })),
  recalculatePay: () => set((state) => {
    let updatedToPay = 0;
    const orderItems = state.order?.items || [];
    const kickstarterItems = state.kickstarterOrder?.items || [];

    for (const orderItem of orderItems) {
      const correlatingKickstarterOrderItem = kickstarterItems.find(item => item.item_uid.$oid === orderItem.item_uid.$oid);

      // Get the base price of the current orderItem product
      const orderItemPrice = state.productData?.find(product => product._id.$oid === orderItem.product_id.$oid)?.base_price || 0;

      if (!correlatingKickstarterOrderItem) {
        updatedToPay += orderItemPrice;
      }

      if (!orderItem.addons) continue;

      for (const addon of orderItem.addons) {
        if (!correlatingKickstarterOrderItem) {
          if (Object.values(state.addonIds).includes(addon.product_id.$oid)) {
            const addonPrice = state.productData?.find(product => product._id.$oid === addon.product_id.$oid)?.base_price || 0;
            updatedToPay += addonPrice;
          }
        } else {
          const itemHasAddons = correlatingKickstarterOrderItem.addons ? true : false;
          const addonPaidFor = itemHasAddons && correlatingKickstarterOrderItem.addons.find(_addon => _addon.product_id.$oid === addon.product_id.$oid) ? true : false;

          if (!addonPaidFor) {
            const addonPrice = state.productData?.find(product => product._id.$oid === addon.product_id.$oid)?.base_price || 0;
            updatedToPay += addonPrice;
          }
        }
      }
    }
    return { toPay: updatedToPay };
  }),

  updateOrderItem: (updatedOrderItem) =>
    set((state) => {
      const updatedOrderItems = state.order.items?.map(orderItem =>
        orderItem.item_uid.$oid === updatedOrderItem.item_uid.$oid
          ? { ...updatedOrderItem, parts: updatedOrderItem.parts || null }
          : orderItem
      )
      return { order: { ...state.order, items: updatedOrderItems } }
    }),
  addOrderItem: (newOrderItem) =>
    set((state) => {
      const doesItExist = state.order.items?.find(item => item.item_uid.$oid === newOrderItem.item_uid.$oid)
      if (doesItExist) {
        return
      }
      const updatedOrderItems = [...state.order.items, newOrderItem]
      return { order: { ...state.order, items: updatedOrderItems } }
    }),
  removeOrderItem: (itemToRemove) => {
    set((state) => {
      const updatedItems = state.order.items.filter(item => item.item_uid.$oid !== itemToRemove.item_uid.$oid);
      const updatedParts = state.order.parts ? state.order.parts.filter(part => part.item_id !== itemToRemove.item_uid.$oid) : [];
      console.log("Removing item, updated items:", updatedItems);
      console.log("Updated parts:", updatedParts);
      return {
        order: {
          ...state.order,
          items: updatedItems,
          parts: updatedParts
        }
      };
    });
  },
  updateShipping: (shippingData) => {
    set((state) => {
      const updatedOrder = { ...state.order, delivery: { ...shippingData } }
      return { order: updatedOrder }
    })
    return get().order;
  },
  createNewOrder: async () => {
    try {
      const response = await fetch('https://swbytn6553.execute-api.eu-west-2.amazonaws.com/prod//orders/createEmptyOrder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error('Failed to create new order')
      }
      const data = await response.json()
      return data.order_id // The new Lambda function returns the new order ID
    } catch (error) {
      console.error('Error creating new order:', error)
      return null
    }
  },
  updateOrderParts: (itemId, newParts, addonId = null) => {
    set((state) => {
      const updatedParts = state.order.parts ? [...state.order.parts] : [];
      const existingItemIndex = updatedParts.findIndex(p => p.item_id === itemId);

      if (existingItemIndex !== -1) {
        if (addonId) {
          // Updating or adding addon parts
          const existingAddonIndex = updatedParts.findIndex(p => p.item_id === itemId && p.addon_id === addonId);
          if (existingAddonIndex !== -1) {
            // Update existing addon parts
            const addonItem = state.order.items.find(item => item.item_uid.$oid === itemId)?.addons.find(addon => addon.item_uid.$oid === addonId);
            if (addonItem && addonItem.type === 'Headband') {
              const headbandPart = addonItem.headband_size === 'adult'
                ? metaData.views[0].front_view.adult_headband
                : metaData.views[0].front_view.headband;
              newParts.headband.part_id = headbandPart.part_id;
            }
            updatedParts[existingAddonIndex] = { item_id: itemId, addon_id: addonId, parts: newParts };
          } else {
            // Add new addon parts
            updatedParts.push({ item_id: itemId, addon_id: addonId, parts: newParts });
          }
        } else {
          // Update main item parts
          updatedParts[existingItemIndex] = { item_id: itemId, parts: newParts };
        }
      } else {
        // Add new item parts
        updatedParts.push({ item_id: itemId, parts: newParts });
      }

      return {
        order: {
          ...state.order,
          parts: updatedParts
        }
      };
    });
  },
}))

export default useOrderStore;
