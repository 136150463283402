import React, { useEffect, useState } from 'react';
import Icons from '../assets/SettingIcons'

const ToggleSwitch = (props) => {
  const [isOn, setIsOn] = useState(false);
  const handleToggle = (value) => {
    setIsOn(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  useEffect(() => {
    if (props.value) {
      setIsOn(true)
    } else {
      setIsOn(false)
    }
  }, [props.value])

  return (
    <div className='flex flex justify-center items-center gap-2'>
      <div className='flex items-end justify-center gap-1'>
        <div className={`children flex flex-col rounded-full w-20 h-20 justify-between py-4 items-center transition-all ease-kibu duration-200 ${isOn ? 'opacity-30 cursor-pointer' : 'bg-orange text-white fill-white opacity-100'}`}
          onClick={() => handleToggle(false)}>
          <div className='w-8 h-8 flex justify-center items-center'>
            <Icons.Headband className={`w-5 h-5`} />
          </div>
          Child
        </div>

        <div className={`adult flex flex-col rounded-full w-20 h-20  justify-between py-4 items-center transition-all ease-kibu duration-200 ${isOn ? 'opacity-100 bg-orange text-white fill-white opacity-100' : 'opacity-30 cursor-pointer'}`}
          onClick={() => handleToggle(true)}>
          <div className='w-8 h-8 flex justify-center items-center'>
            <Icons.Headband className='w-8 h-8' />
          </div>
          <div className={`${isOn ? 'opacity-100' : 'opacity-100'}`}>Adult</div>
        </div>
      </div>
    </div>
  )
};

export default ToggleSwitch;
