import React, { useState } from 'react';
import { metaData } from '../assets/headphone-parts-image_meta-data';

const ColourPicker = ({ className, selectedColour, setSelectedColour }) => {
  const [colours, setColours] = useState(metaData.colours.available_colours);
  const [hoveredColour, setHoveredColour] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const handleMouseEnter = (colour) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setHoveredColour(colour);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setHoveredColour(null);
    }, 500);
    setHoverTimeout(timeout);
  };

  const midpoint = Math.ceil(colours.length / 2);
  const firstRow = colours.slice(0, midpoint);
  const secondRow = colours.slice(midpoint);

  const currentColour = hoveredColour || selectedColour;
  const currentHex = colours.find(colour => colour.name === currentColour)?.hex || '#FFFFFF';

  return (
    <div className={`flex flex-col relative z-50 gap-2 rounded-md bg-[#000000]/85 shadow-big backdrop-blur-sm p-3 md:p-4 transition-all ease-kibu duration-200 delay-75 ${className}`}>
      {/* Single row for mobile */}
      <div className="flex flex-row gap-2 md:hidden items-center justify-center">
        {colours.map((obj, key) => {
          return (
            <div key={key}
              className={`w-8 h-8 md:w-8 md:h-8 rounded-full transition-all ease-kibu duration-200
                ${selectedColour === obj.name ? 'border border-4 border-white' : 'cursor-pointer hover:scale-125'}`}
              style={{ backgroundColor: obj.hex }}
              onClick={(e) => {
                setSelectedColour(obj.name);
                e.stopPropagation();
              }}
              onMouseEnter={() => handleMouseEnter(obj.name)}
              onMouseLeave={() => handleMouseLeave()}>
            </div>
          );
        })}
      </div>

      {/* Two rows for md and above */}
      <div className="hidden md:flex flex-row gap-2 md:items-center justify-center">
        {firstRow.map((obj, key) => {
          return (
            <div key={key}
              className={`w-8 h-8 md:w-8 md:h-8 rounded-full transition-all ease-kibu duration-200
                ${selectedColour === obj.name ? 'border border-4 border-white' : 'cursor-pointer hover:scale-125'}`}
              style={{ backgroundColor: obj.hex }}
              onClick={(e) => {
                setSelectedColour(obj.name);
                e.stopPropagation();
              }}
              onMouseEnter={() => handleMouseEnter(obj.name)}
              onMouseLeave={() => handleMouseLeave()}>
            </div>
          );
        })}
      </div>
      <div className="hidden md:flex flex-row gap-2 md:items-center justify-center mt-2">
        {secondRow.map((obj, key) => {
          return (
            <div key={key}
              className={`w-8 h-8 md:w-8 md:h-8 rounded-full transition-all ease-kibu duration-200
                ${selectedColour === obj.name ? 'border border-4 border-white' : 'cursor-pointer hover:scale-125'}`}
              style={{ backgroundColor: obj.hex }}
              onClick={(e) => {
                setSelectedColour(obj.name);
                e.stopPropagation();
              }}
              onMouseEnter={() => handleMouseEnter(obj.name)}
              onMouseLeave={() => handleMouseLeave()}>
            </div>
          );
        })}
      </div>

      <div className="text-center" style={{ color: currentHex }}>
        {currentColour ? currentColour : "Select a colour"}
      </div>
    </div>
  );
};

export default ColourPicker;
